/**
 * @NOTE names of the fields are not 100% accurate and are arbitrary
 */
export const unitExportFields = {
  CertificateId: {
    name: 'Certificate ID',
    path: 'computed.blockId',
  },
  IssuingId: {
    name: 'Issuing SAFc ID',
    path: 'computed.absoluteRootId',
  },
  UnitTypeCode: {
    name: 'Unit type code',
    path: 'computed.typeCode',
  },
  AccountsHeldUnit: {
    name: 'Accounts that held unit',
    path: 'computed.historyOwnerTypes',
  },
  TonnesOfSAF: {
    name: 'Tonnes of SAF',
    path: 'volume',
  },
  CarbonIntensityScore: {
    name: 'Carbon intensity score (g CO2eq/MJ)',
    path: 'computed.carbonIntensityScore',
  },
  EmissionReductionToJetFuel: {
    name: 'Emission reduction compared to jet fuel baseline (%)',
    path: 'computed.ghgReduction',
  },
  CO2abated: {
    name: 'Tonnes CO2e abated',
    path: 'computed.co2Abated',
  },
  IssuanceDate: {
    name: 'Issuance date',
    path: 'computed.issuanceDate',
  },
  ExpiryDate: {
    name: 'Expiry date',
    path: 'computed.expirationDate',
  },
  Status: {
    name: 'Status',
    path: 'computed.status',
  },
  FuelProvider: {
    name: 'Fuel provider',
    path: 'computed.rootOwnerName',
  },
  FuelProducingCompanyName: {
    name: 'Fuel producing company name',
    path: 'computed.rootMetadata.batch.companyName',
  },
  FuelProducingFacilityName: {
    name: 'Fuel producing facility name',
    path: 'computed.rootMetadata.supplier.siteName',
  },
  FuelProviderCompanyName: {
    name: 'Fuel provider company name',
    path: 'computed.rootMetadata.batch.fuelProviderCompanyName',
  },
  DateOfSafLeavingCustody: {
    name: 'Date of SAF leaving the issuer\'s chain of custody',
    path: 'computed.rootMetadata.batch.leaveDate',
  },
  BatchNumberSaf: {
    name: 'Batch number of SAF',
    path: 'computed.rootMetadata.batch.batchNumber',
  },
  YearOfSafProduction: {
    name: 'Year of SAF production',
    path: 'computed.rootMetadata.batch.productionYear',
  },
  CountryOfSafUplift: {
    name: 'Country of SAF uplift',
    path: 'computed.rootMetadata.batch.upliftCountry.name',
  },
  AirportOfSafUplift: {
    name: 'Airport of SAF uplift',
    path: 'computed.rootMetadata.batch.upliftAirport',
  },
  RegulatorySchemes: {
    name: 'Regulatory schemes applicable to SAF production',
    path: 'computed.rootMetadata.batch.regulatorySchemesDeclaration',
  },
  IsBlended: {
    name: 'Is the SAF blended with conventional aviation fuel or certified as drop-in according to ASTM Standard D7566 or D1655?',
    path: 'computed.rootMetadata.blending.isBlended',
  },
  IsBlendedDate: {
    name: 'Date SAF was blended with conventional jet fuel or certification as drop-in',
    path: 'computed.rootMetadata.blending.blendingDate',
  },
  IsBlendedLocation: {
    name: 'Location where SAF was blended with conventional jet fuel or certification as drop-in',
    path: 'computed.rootMetadata.blending.blendingLocation'
  },
  FuelProducingFacilityAddress: {
    name: 'Address of the fuel production facility',
    path: 'computed.rootMetadata.supplier.siteAddress',
  },
  FuelCertificationScheme: {
    name: 'Fuel certification scheme',
    path: 'computed.certificationScheme',
  },
  ActualOrDefaultLCA: {
    name: 'Actual or Default LCA',
    path: 'computed.rootMetadata.certification.type',
  },
  FuelProducerCertNumber: {
    name: 'Fuel producer’s sustainability certification number',
    path: 'computed.rootMetadata.certification.certificateNumber',
  },
  FuelProducerCertificationExpiryDate: {
    name: 'Expiry date of the fuel producer’s sustainability certification',
    path: 'computed.rootMetadata.certification.expiryDate',
  },
  FuelProducerCertificationBody: {
    name: 'Certification body that provided the fuel producer\'s sustainability certification',
    path: 'computed.rootMetadata.certification.certificationBody',
  },
  FuelProviderTraderCertificationNumber: {
    name: 'Fuel provider\'s trader certification number',
    path: 'computed.rootMetadata.certification.traderCertificationNumber',
  },
  AdditionalFuelCertification: {
    name: 'Additional fuel certification schemes standards and evaluation methodologies',
    path: 'computed.rootMetadata.certification.additionalScheme',
  },
  LowILUCisConfirmed: {
    name: 'Was low ILUC risk confirmed by an audit?',
    path: 'computed.rootMetadata.certification.isLowILUCRiskConfirmed',
  },
  LowILUCCertificationNumber: {
    name: 'Certification number of low-ILUC risk audit',
    path: 'computed.rootMetadata.certification.lowRiskAuditCertificateNumber'
  },
  LowILUCCertificationExpiryDate: {
    name: 'Expiry date of the certification of low-ILUC risk audit',
    path: 'computed.rootMetadata.certification.lowRiskAuditExpiryDate'
  },
  LowILUCCertificationBody: {
    name: 'Certification body that provided the low-ILUC risk audit',
    path: 'computed.rootMetadata.certification.lowRiskAuditCertificationBody',
  },
  LowDisplacementIsConfirmed: {
    name: 'Was low displacement risk confirmed by an audit?',
    path: 'computed.rootMetadata.certification.isLowDisplacementRiskConfirmed',
  },
  LowDisplacementCertificationNumber: {
    name: 'Certification number of low-displacement risk audit',
    path: 'computed.rootMetadata.certification.lowDisplacementRiskAuditCertificateNumber',
  },
  LowDisplacementCertificationExpiryDate: {
    name: 'Expiry date of the certification of low-displacement risk audit',
    path: 'computed.rootMetadata.certification.lowDisplacementRiskAuditExpiryDate',
  },
  LowDisplacementCertificationBody: {
    name: 'Name of the certification body responsible for the low-displacement risk audit',
    path: 'computed.rootMetadata.certification.lowDisplacementRiskAuditCertificationBody',
  },
  FeedstockSaf: {
    name: 'The feedstock used to make the SAF',
    path: 'computed.productionFeedstock',
  },
  FeedstockMunicipalSolidWaste: {
    name: 'Is feedstock municipal solid waste?',
    path: 'computed.rootMetadata.product.feedStock.isSolidWaste',
  },
  FeedstockMunicipalSolidWasteNonBiologicalContent: {
    name: 'If municipal solid waste, % of non-biological content',
    path: 'computed.rootMetadata.product.isFeedStockMsw',
  },
  FeedstockCertifiedAsWasteByProductResidue: {
    name:
      'Was the feedstock certified as a waste, by-product or residue?',
    path: 'computed.rootMetadata.product.feedStock.wasCertifiedAsProductionResidue',
  },
  FeedstockCountry: {
    name: 'Feedstock country or countries of origin',
    path: 'computed.rootMetadata.product.countries',
  },
  CountryOfSafProduction: {
    name: 'Country of SAF Production',
    path: 'computed.countryOfProductionName',
  },
  MassOfNeatSaf: {
    name: 'Mass of neat SAF of original issuance',
    path: 'computed.rootMetadata.product.quantityMT',
  },
  VolumeOfNeatSaf: {
    name: 'Volume of neat SAF of original issuance',
    path: 'computed.rootMetadata.product.volumeOfNeatFuel',
  },
  VolumeOfNeatSafUnit: {
    name: 'Volume of neat fuel unit',
    path: 'computed.rootMetadata.product.volumeOfNeatFuelUnit',
  },
  SAFConversionProcess: {
    name: 'SAF conversion process',
    path: 'computed.rootMetadata.product.conversionProcess',
  },
  SAFLowerHeatingValue: {
    name: 'SAF lower heating value (MJ/kg)',
    path: 'computed.rootMetadata.product.lowerHeatingValue',
  },
  ConventionalJetFuelCarbonIntensity: {
    name: 'Conventional jet fuel baseline carbon intensity (well to wake) (g CO2e/MJ)',
    path: 'computed.rootMetadata.product.jetFuelBaselineCarbonIntensity',
  },
  TotalSafLCAValue: {
    name: 'Total SAF LCA value (gCO2e/MJ)',
    path: 'computed.totalLCAValue',
  },
  CoreLCAValue: {
    name: 'Core LCA value (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.coreLCAValue'
  },
  InducedLandUseChange: {
    name: 'Induced Land Use Change (ILUC) value (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.inducedLandUseChange',
  },
  RecyclingEmissionsCredits: {
    name: 'Value of Recycling Emissions Credits (RECs) in the total SAF LCA value (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.recyclingEmissionsCredits',
  },
  LandfillEmissionsCredits: {
    name: 'Value of Landfill Emissions Credits (LECs) in the total SAF LCA value (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.recyclingEmissionsCredits',
  },
  SafLifecycleEmissions: {
    name: 'SAF life cycle emissions value (well to wake) (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.landfillEmissionsCredits',
  },
  EmissionsFromDisplacement: {
    name: 'GHG Emissions from displacement (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.ghgDisplacementEmissions',
  },
  EmissionsFromProductionAtSource: {
    name: 'Emisions from production at source (e.g. feedstock cultivation)',
    path: 'computed.rootMetadata.greenhouseGas.production',
  },
  EmissionsFromConditioningAtSource: {
    name: 'Emissions from conditioning at source (e.g. feedstock harvesting, collection and recovery)',
    path: 'computed.rootMetadata.greenhouseGas.conditioning',
  },
  EmissionsFromFeedstockProcessingAndExtraction: {
    name: 'Emissions from feedstock processing and extraction',
    path: 'computed.rootMetadata.greenhouseGas.processing',
  },
  EmissionsFromFeedstockTransportation: {
    name: 'Emissions from feedstock transportation to processing and fuel production faclities',
    path: 'computed.rootMetadata.greenhouseGas.transportation',
  },
  EmissionsFromFeedstockConversion: {
    name: 'Emissions from feedstock-to-fuel conversion processes',
    path: 'computed.rootMetadata.greenhouseGas.conversionProcesses',
  },
  EmissionsFromFuelTransportation: {
    name: 'Emissions from fuel transportation and distribution to the blend point',
    path: 'computed.rootMetadata.greenhouseGas.fuelTransportation',
  },
  EmissionsFromFuelCombustion: {
    name: 'Emissions from fuel combustion in an aircraft engine',
    path: 'computed.rootMetadata.greenhouseGas.combustion',
  },
  EmissionsSavingsFromSoilCarbonAccumulation: {
    name: 'Emissions savings from soil carbon accumulation via improved agricultural mgmt (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.savingsFromSoil'
  },
  EmissionsFromExtractionRawMaterials: {
    name: 'Emissions from extraction or cultivation of raw materials (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.extractionEmissions',
  },
  AnnualisedGHGEmissionsLandUse: {
    name: 'Annualised (over 20 yrs) GHG emissions from carbon stock change due to land use change (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.annualisedEmissions',
  },
  EmissionsFromProcessing: {
    name: 'Emissions from processing (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.processingEmissions',
  },
  EmissionsFromTransportAndDistribution: {
    name: 'Emissions from transport and distribution (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.transportEmissions',
  },
  EmissionsFromFuelInUse: {
    name: 'Emissions from the fuel in use (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.inUseFuelEmissions',
  },
  EmissionsSavingsFromCarbonCaptureAndGeoStorage: {
    name: 'Emissions savings from carbon capture and geological storage (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.savingsFromCarbonAndStorage',
  },
  EmissionsSavingsFromCarbonCaptureAndReplacement: {
    name: 'Emissions savings from carbon capture and replacement (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.savingsFromCarbonAndReplacement',
  },
  EmissionsSavingsFromExcessElecricity: {
    name: 'Emissions savings from excess electricity from cogeneration (gCO2e/MJ)',
    path: 'computed.rootMetadata.greenhouseGas.savingsFromExcess',
  },
  EmissionsSavingsFromSequestration: {
    name: 'Emissions savings due to soil carbon sequestration',
    path: 'computed.rootMetadata.greenhouseGas.emissionsSavingsDueToSoilCarbonSequestration',
  },
  EmissionsSavingsFromCCS: {
    name: 'Emissions savings from CCS (if applicable)',
    path: 'computed.rootMetadata.greenhouseGas.emissionsSavingsFromCSS',
  },
  TotalLCAValueIncludesEmissionsFromDeliveryToAirport: {
    name: 'Does the total LCA value include emissions from delivery to airport',
    path: 'computed.rootMetadata.greenhouseGas.totalLCAValueincludeEmissionsFromDeliveryToAirport',
  },
  DefaultValueForDelivery: {
    name: 'Do you want to use the default 0.9gCO2e/MJ vlaue for delivery to airport',
    path: 'computed.rootMetadata.greenhouseGas.useDefaultValueForDeliveryToAirport',
  },
  ActualGHGIntensityValue: {
    name: 'The actual GHG intensity value of airport delivery (if not included in total LCA)',
    path: 'computed.rootMetadata.greenhouseGas.actualGHGIntensityAirportDelivery',
  },
  RetirementAirTransportProviderBeneficiary: {
    name: 'Air Transport Provider Beneficiary',
    path: 'computed.SAFcAClaimBeneficiaryCompanyName'
  },
  RetirementDate: {
    name: 'Retirement date',
    path: 'computed.retiredAt',
  },
  RetirementClaimYear: {
    name: 'Retirement claim year',
    path: 'metadata.retirementYear',
  },
  RetirementCountryWhereSafcClaim: {
    name: 'Country where SAFc will be claimed',
    path: 'metadata.retirementCountry',
  },
  RetirementEmissionsClaimLocation: {
    name: 'Emissions claim location',
    path: 'metadata.claimPurpose'
  },
  RetirementEndUserBeneficiary: {
    name: 'End-user beneficiary',
    path: 'computed.SAFcEClaimBeneficiaryCompanyName',
  },
  RetirementLogisticsProviderBeneficiary: {
    name: 'Logistics Provider Beneficiary',
    path: 'computed.logisticsProviderBeneficiaryCompanyName'
  },
  UniqueCompaniesThatHeldUnit: {
    name: 'Unique companies that have held the unit',
    path: 'computed.historyCompanyCount'
  }
};

export const processExportFields = {
  ActionID: {
    name: 'Action ID',
    path: 'id',
  },
  ActionType: {
    name: 'Action Type',
    path: 'computed.transactionType',
  },
  SendingAccount: {
    name: 'Sending account',
    path: 'sendingAccountName',
  },
  SendingCompany: {
    name: 'Sending company',
    path: 'sendingCompanyName',
  },
  Initiator: {
    name: 'Initiator',
    path: 'actor.userName',
  },
  ReceivingAccount: {
    name: 'Receiving account',
    path: 'receivingAccountName',
  },
  ReceivingCompany: {
    name: 'Receiving company',
    path: 'receivingCompanyName',
  },
  RequiredActions: {
    name: 'Required actions',
    path: 'computed.requiredActions',
  },
  Status: {
    name: 'Status',
    path: 'computed.status',
  },
  TransactionDate: {
    name: 'Transaction date',
    path: 'createdAt',
  }
};
