import { without } from 'lodash';
import { processExportFields, unitExportFields } from './export-fields';

/** Maintains order defined in `moveToTop` */
const moveToBeginning = <T>(sourceArray: T[], moveToTop: T[]): T[] => {
  const copy = sourceArray.slice();
  const reversedMove = moveToTop.toReversed();

  for (const item of reversedMove) {
    const index = copy.findIndex(value => value === item);

    if (index < 0) {
      continue;
    }

    copy.splice(index, 1);
    copy.unshift(item);
  }

  return copy;
};

const unitHeader = [
  unitExportFields.CertificateId,
  unitExportFields.IssuingId,
  unitExportFields.UnitTypeCode,
];

const unitDetails = [
  unitExportFields.AccountsHeldUnit,
  unitExportFields.TonnesOfSAF,
  unitExportFields.CarbonIntensityScore,
  unitExportFields.EmissionReductionToJetFuel,
  unitExportFields.CO2abated,
  unitExportFields.IssuanceDate,
  unitExportFields.ExpiryDate,
  unitExportFields.Status,
  unitExportFields.FuelProvider,
  unitExportFields.FuelProducingCompanyName,
  unitExportFields.FuelProducingFacilityName,
  unitExportFields.FuelProviderCompanyName,
  unitExportFields.DateOfSafLeavingCustody,
  unitExportFields.BatchNumberSaf,
  unitExportFields.YearOfSafProduction,
  unitExportFields.CountryOfSafUplift,
  unitExportFields.AirportOfSafUplift,
  unitExportFields.RegulatorySchemes,
  unitExportFields.IsBlended,
  unitExportFields.IsBlendedDate,
  unitExportFields.IsBlendedLocation,
  unitExportFields.FuelProducingFacilityAddress,
  unitExportFields.FuelCertificationScheme,
  unitExportFields.ActualOrDefaultLCA,
  unitExportFields.FuelProducerCertNumber,
  unitExportFields.FuelProducerCertificationExpiryDate,
  unitExportFields.FuelProducerCertificationBody,
  unitExportFields.FuelProviderTraderCertificationNumber,
  unitExportFields.AdditionalFuelCertification,
  unitExportFields.LowILUCisConfirmed,
  unitExportFields.LowILUCCertificationNumber,
  unitExportFields.LowILUCCertificationExpiryDate,
  unitExportFields.LowILUCCertificationBody,
  unitExportFields.LowDisplacementIsConfirmed,
  unitExportFields.LowDisplacementCertificationNumber,
  unitExportFields.LowDisplacementCertificationExpiryDate,
  unitExportFields.LowDisplacementCertificationBody,
  unitExportFields.FeedstockSaf,
  unitExportFields.FeedstockMunicipalSolidWaste,
  unitExportFields.FeedstockMunicipalSolidWasteNonBiologicalContent,
  unitExportFields.FeedstockCertifiedAsWasteByProductResidue,
  unitExportFields.FeedstockCountry,
  unitExportFields.CountryOfSafProduction,

  unitExportFields.MassOfNeatSaf,
  unitExportFields.VolumeOfNeatSaf,
  unitExportFields.VolumeOfNeatSafUnit,
  unitExportFields.SAFConversionProcess,
  unitExportFields.SAFLowerHeatingValue,
  unitExportFields.ConventionalJetFuelCarbonIntensity,
  unitExportFields.TotalSafLCAValue,
  unitExportFields.CoreLCAValue,
  unitExportFields.InducedLandUseChange,
  unitExportFields.RecyclingEmissionsCredits,
  unitExportFields.LandfillEmissionsCredits,
  unitExportFields.SafLifecycleEmissions,
  unitExportFields.EmissionsFromDisplacement,
  unitExportFields.EmissionsFromProductionAtSource,
  unitExportFields.EmissionsFromConditioningAtSource,
  unitExportFields.EmissionsFromFeedstockProcessingAndExtraction,
  unitExportFields.EmissionsFromFeedstockTransportation,
  unitExportFields.EmissionsFromFeedstockConversion,
  unitExportFields.EmissionsFromFuelTransportation,
  unitExportFields.EmissionsFromFuelCombustion,
  unitExportFields.EmissionsSavingsFromSoilCarbonAccumulation,
  unitExportFields.EmissionsFromExtractionRawMaterials,
  unitExportFields.AnnualisedGHGEmissionsLandUse,
  unitExportFields.EmissionsFromProcessing,
  unitExportFields.EmissionsFromTransportAndDistribution,
  unitExportFields.EmissionsFromFuelInUse,
  unitExportFields.EmissionsSavingsFromCarbonCaptureAndGeoStorage,
  unitExportFields.EmissionsSavingsFromCarbonCaptureAndReplacement,
  unitExportFields.EmissionsSavingsFromExcessElecricity,
  unitExportFields.EmissionsSavingsFromSequestration,
  unitExportFields.EmissionsSavingsFromCCS,
  unitExportFields.TotalLCAValueIncludesEmissionsFromDeliveryToAirport,
  unitExportFields.DefaultValueForDelivery,
  unitExportFields.ActualGHGIntensityValue,
];

const unitExport = [
  ...unitHeader,
  ...unitDetails,
];

const safcARetirementSpecific = [
  unitExportFields.RetirementAirTransportProviderBeneficiary,
];

const safcERetirementSpecific = [
  unitExportFields.RetirementEndUserBeneficiary,
  unitExportFields.RetirementLogisticsProviderBeneficiary,
];

const retirementFields = [
  unitExportFields.RetirementDate,
  unitExportFields.RetirementClaimYear,
  unitExportFields.RetirementCountryWhereSafcClaim,
  unitExportFields.RetirementEmissionsClaimLocation,
  unitExportFields.UniqueCompaniesThatHeldUnit,
];

export const accountHoldingsExport = unitExport;

// this is not public
// Some fields are missing, and some are reorderded
export const issuanceTableExport = [
  ...without(unitHeader, unitExportFields.CertificateId),

  ...moveToBeginning(
    // not displayed because it doesn't make sense for issuances
    without(unitDetails, unitExportFields.AccountsHeldUnit, unitExportFields.TonnesOfSAF, unitExportFields.Status),
    // moved to top of the list
    [
      unitExportFields.MassOfNeatSaf,
      unitExportFields.VolumeOfNeatSaf,
      unitExportFields.VolumeOfNeatSafUnit,
    ]
  )
];

export const pendingActionsExport = [
  processExportFields.ActionID,
  processExportFields.ActionType,
  processExportFields.SendingAccount,
  processExportFields.SendingCompany,
  processExportFields.Initiator,
  processExportFields.ReceivingAccount,
  processExportFields.ReceivingCompany,
  processExportFields.Status,
  processExportFields.RequiredActions,
  // unit status doesn't make sense for pending action
  ...without(unitExport, unitExportFields.Status),
];

export const holdingsHistoryExport = [
  processExportFields.ActionID,
  processExportFields.ActionType,
  processExportFields.TransactionDate,
  processExportFields.SendingAccount,
  processExportFields.SendingCompany,
  processExportFields.Initiator,
  processExportFields.ReceivingAccount,
  processExportFields.ReceivingCompany,
  processExportFields.Status,
  // unit status doesn't make sense for history action
  ...without(unitExport, unitExportFields.Status),
];

export const safcARetirementExport = [
  ...unitHeader,
  ...safcARetirementSpecific,
  ...retirementFields,
  ...unitDetails,
];

export const safcERetirementExport = [
  ...unitHeader,
  ...safcERetirementSpecific,
  ...retirementFields,
  ...unitDetails,
];

export const publicRetirement = [
  ...unitHeader,
  ...safcARetirementSpecific,
  ...safcERetirementSpecific,
  ...retirementFields,
  unitExportFields.AccountsHeldUnit,
  unitExportFields.TonnesOfSAF,
  unitExportFields.CO2abated,
  unitExportFields.CarbonIntensityScore,
  unitExportFields.EmissionReductionToJetFuel,
  unitExportFields.IssuanceDate,
  unitExportFields.ExpiryDate,
  unitExportFields.FuelProducingCompanyName,
  unitExportFields.FuelProducingFacilityName,
  unitExportFields.YearOfSafProduction,
  unitExportFields.AirportOfSafUplift,
  unitExportFields.FuelCertificationScheme,
  unitExportFields.RegulatorySchemes,
  unitExportFields.AdditionalFuelCertification,
  unitExportFields.FeedstockSaf,
  unitExportFields.FeedstockCountry,
  unitExportFields.SAFConversionProcess,
  unitExportFields.ConventionalJetFuelCarbonIntensity,
  unitExportFields.CountryOfSafProduction,
];
