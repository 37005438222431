/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SAFc Backend
 * The 24/7 API description
 * OpenAPI spec version: 1.0
 */
export type DevControllerMockPaymentParams = {
invoiceId: string;
};

export type DevControllerMockKycResultParams = {
processId: string;
result: string;
};

export type WorkerControllerGetNextMessage200 = { [key: string]: any };

export type AnalyticsControllerGetAnalyticsTokenParams = {
companyId?: string;
};

export type PublicControllerGetUnitsParams = {
type: UnitType;
history?: boolean;
};

export type LogsControllerGetAllLogsParams = {
take: number;
/**
 * Starts counting from 0
 */
page?: number;
};

export type LogsControllerGetLogsByAffectedParams = {
take: number;
/**
 * Starts counting from 0
 */
page?: number;
companyIds: string[];
accountIds: string[];
userIds: string[];
};

export type UnitProcessControllerCancelParams = {
accountId: string;
};

export type UnitProcessControllerSignaturesRejectParams = {
accountId: string;
};

export type UnitProcessControllerSignaturesSignParams = {
accountId: string;
};

export type UnitProcessControllerAcceptanceRejectParams = {
accountId: string;
};

export type UnitProcessControllerClaimAcceptanceAcceptParams = {
accountId: string;
};

export type UnitProcessControllerTransferAcceptanceAcceptParams = {
accountId: string;
};

export type UnitProcessControllerClaimParams = {
accountId: string;
};

export type UnitProcessControllerGetUnitProcessesParams = {
state: QueryUnitProcessState;
accountId: string;
};

export type UnitControllerGetUnitsUnitType = typeof UnitControllerGetUnitsUnitType[keyof typeof UnitControllerGetUnitsUnitType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitControllerGetUnitsUnitType = {
  SAFcA: 'SAFcA',
  SAFcE: 'SAFcE',
} as const;

export type UnitControllerGetUnitsParams = {
ownerId: string;
history?: boolean;
type?: UnitType;
unitType?: UnitControllerGetUnitsUnitType;
unitIds?: string[];
};

export type ApprovalControllerGetAllWithPayloadParams = {
types?: ApprovalType[];
states?: ApprovalState[];
};

export type ActionsControllerGetPendingActionsParams = {
companyId?: string;
};

export type ActionsControllerGetPendingActionsCountParams = {
companyId?: string;
};

export type UserControllerGetAccountInvitedUsersParams = {
companyId: string;
};

export type UserControllerGetCompanyInvitedUsersParams = {
companyId: string;
};

export type UserControllerGetCompanyUsersRole = typeof UserControllerGetCompanyUsersRole[keyof typeof UserControllerGetCompanyUsersRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserControllerGetCompanyUsersRole = {
  companyRepresentative: 'companyRepresentative',
  regular: 'regular',
} as const;

export type UserControllerGetCompanyUsersParams = {
role?: UserControllerGetCompanyUsersRole;
companyId: string;
};

export type UserControllerGetUserDataParams = {
companyId: string;
};

export type BillingControllerListInvoicesParams = {
companyId?: string;
};

export type AccountControllerCloseAccountRepParams = {
companyId?: string;
};

export type AccountControllerGetUserAccountsParams = {
companyId: string;
};

export type AccountControllerGetMyAccountsType = typeof AccountControllerGetMyAccountsType[keyof typeof AccountControllerGetMyAccountsType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountControllerGetMyAccountsType = {
  airTransportProviderHoldingAccount: 'airTransportProviderHoldingAccount',
  certificationBody: 'certificationBody',
  fuelProviderHoldingAccount: 'fuelProviderHoldingAccount',
  generalHoldingAccount: 'generalHoldingAccount',
  logisticsProviderHoldingAccount: 'logisticsProviderHoldingAccount',
} as const;

export type AccountControllerGetMyAccountsParams = {
companyId?: string;
type?: AccountControllerGetMyAccountsType;
/**
 * Skip closed accounts in listing
 */
skipClosed?: boolean;
/**
 * Skip rejected accounts in listing
 */
skipRejected?: boolean;
};

export type AccountControllerCreateParams = {
companyId: string;
};

export type AccountControllerUpdateAccountParams = {
companyId?: string;
};

export type AccountControllerListAccountsToTransferParams = {
accountId: string;
};

export type CompanyControllerCloseCompanyParams = {
companyId: string;
};

export type CompanyControllerPromoteToCompanyRepsParams = {
companyId: string;
};

export type CompanyControllerRevokeCompanyRepInviteParams = {
companyId: string;
};

export type CompanyControllerAddCompanyRepParams = {
companyId: string;
};

export type CompanyControllerUpdateCompanyDocumentsParams = {
companyId: string;
};

export type CompanyControllerUnblockCompanyParams = {
companyId: string;
};

export type CompanyControllerBlockCompanyParams = {
companyId: string;
};

export type CompanyControllerUpdateNamePrivacyParams = {
companyId: string;
};

export type CompanyControllerCloseMembershipParams = {
companyId: string;
};

export type CompanyControllerRemoveCompanyParams = {
companyId: string;
};

export type CompanyControllerUpdateCompanyParams = {
companyId: string;
};

export type CompanyControllerGetCompanyDataParams = {
companyId: string;
};

export type FileControllerGetFilesDetailsParams = {
fileIds: string[];
};

export type TransferPayloadRequest = {
  accountId: string;
  blockId: string;
  recipientId?: string;
  volume: number;
};

export type SplitPayloadRequest = {
  accountId: string;
  blockId: string;
  volume: number;
};

export type ClaimPayloadRequestSercClaimBeneficiaryOneOfTwo = {
  email: string;
  name: string;
};

export type ClaimPayloadRequestSercClaimBeneficiaryOneOf = {
  ownerId: string;
};

export type ClaimPayloadRequestSercClaimBeneficiary = ClaimPayloadRequestSercClaimBeneficiaryOneOf | ClaimPayloadRequestSercClaimBeneficiaryOneOfTwo;

export type ClaimPayloadRequest = {
  accountId: string;
  blockId: string;
  claimPurpose?: ClaimPayloadRequestClaimPurpose;
  complianceRegime?: ClaimPayloadRequestComplianceRegime;
  logisticsProviderBeneficiary?: string;
  retirementCountry: string;
  retirementOnRegistryYear?: number;
  retirementYear: number;
  safcClaimBeneficiary?: ClaimPayloadRequestSafcClaimBeneficiary;
  sercClaimBeneficiary?: ClaimPayloadRequestSercClaimBeneficiary;
  volume: number;
};

export type ClaimPayloadRequestSafcClaimBeneficiaryOneOfTwo = {
  email: string;
  name: string;
};

export type ClaimPayloadRequestSafcClaimBeneficiaryOneOf = {
  ownerId: string;
};

export type ClaimPayloadRequestSafcClaimBeneficiary = ClaimPayloadRequestSafcClaimBeneficiaryOneOf | ClaimPayloadRequestSafcClaimBeneficiaryOneOfTwo;

export type ClaimPayloadRequestComplianceRegime = typeof ClaimPayloadRequestComplianceRegime[keyof typeof ClaimPayloadRequestComplianceRegime];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimPayloadRequestComplianceRegime = {
  corsia: 'corsia',
  'not-corsia': 'not-corsia',
} as const;

export type ClaimPayloadRequestClaimPurpose = typeof ClaimPayloadRequestClaimPurpose[keyof typeof ClaimPayloadRequestClaimPurpose];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimPayloadRequestClaimPurpose = {
  domestic: 'domestic',
  international: 'international',
} as const;

export type AuthError = typeof AuthError[keyof typeof AuthError];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthError = {
  unknown: 'unknown',
  notRegistered: 'notRegistered',
} as const;

export type AuthErrorDTO = {
  error: AuthError;
};

export type ErrorResponseDetails = { [key: string]: any };

export type ErrorResponse = {
  details: ErrorResponseDetails;
  errorId: string;
  message: string;
  statusCode: number;
};

export type AuthTokenCreatedResponse = {
  createdAt: string;
  id: string;
  name: string;
  token: string;
};

export type CreateTokenRequest = {
  name: string;
};

export type AuthTokenResponse = {
  createdAt: string;
  id: string;
  name: string;
};

export type CensorshipRuleDTOType = typeof CensorshipRuleDTOType[keyof typeof CensorshipRuleDTOType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CensorshipRuleDTOType = {
  single: 'single',
  tree: 'tree',
} as const;

export type CensorshipRuleDTO = {
  id: string;
  paths: string[];
  type: CensorshipRuleDTOType;
};

export type GetRulesForUnitResponse = {
  rules: CensorshipRuleDTO[];
};

export type NewCensorshipRequestType = typeof NewCensorshipRequestType[keyof typeof NewCensorshipRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewCensorshipRequestType = {
  single: 'single',
  tree: 'tree',
} as const;

export type NewCensorshipRequest = {
  paths: string[];
  type: NewCensorshipRequestType;
  unitId: string;
};

export type AnalyticsTokenResponse = {
  dashboardId: string;
  dashboardUrl: string;
  token: string;
};

export type PublicExportRequest = {
  fields?: ExportRequestField[];
  fileName?: string;
  history?: boolean;
  type: UnitType;
  unitIds?: string[];
};

export type LandingPageDataResponse = {
  airTransportProviders: number;
  aviationCustomers: number;
  co2Abated: number;
  fuelProviders: number;
  logisticServiceProviders: number;
  safCaVolume: number;
  supplyCountriesOfOrigin: number;
  uniqueSuppliers: number;
};

export type PublicUnitResponse = {
  computed: MaybeCensoredComputed;
  id: string;
  isLast: MaybeCensoredIsLast;
  metadata: MaybeCensoredMetadata;
  ownerId: MaybeCensoredOwnerId;
  rootId: string;
  status: MaybeCensoredStatus;
  type: MaybeCensoredType;
  volume: number;
};

export type PermissionsResponse = {
  canHidePublicTableData: boolean;
};

export type SimpleUserResponse = {
  isAuthenticated: boolean;
  permissions: PermissionsResponse;
  userId: string | null;
};

export type GetSauronEventsResponse = {
  count: number;
  events: GetSauronEventsResponseEventsItem[];
  pages: number;
};

export type CompanyRemovalRequestedType = typeof CompanyRemovalRequestedType[keyof typeof CompanyRemovalRequestedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRemovalRequestedType = {
  CompanyRemovalRequested: 'CompanyRemovalRequested',
} as const;

export type CompanyRemovalRequested = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyRemovalRequestedType;
};

export type UnitProcessInitiatedType = typeof UnitProcessInitiatedType[keyof typeof UnitProcessInitiatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessInitiatedType = {
  UnitProcessInitiated: 'UnitProcessInitiated',
} as const;

export type UnitProcessInitiated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UnitProcessInitiatedType;
};

export type UnitProcessCancelledType = typeof UnitProcessCancelledType[keyof typeof UnitProcessCancelledType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessCancelledType = {
  UnitProcessCancelled: 'UnitProcessCancelled',
} as const;

export type UnitProcessCancelled = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UnitProcessCancelledType;
};

export type UnitProcessApprovalRejectedType = typeof UnitProcessApprovalRejectedType[keyof typeof UnitProcessApprovalRejectedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessApprovalRejectedType = {
  UnitProcessApprovalRejected: 'UnitProcessApprovalRejected',
} as const;

export type UnitProcessApprovalRejected = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UnitProcessApprovalRejectedType;
};

export type UnitProcessApprovalApprovedType = typeof UnitProcessApprovalApprovedType[keyof typeof UnitProcessApprovalApprovedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessApprovalApprovedType = {
  UnitProcessApprovalApproved: 'UnitProcessApprovalApproved',
} as const;

export type UnitProcessApprovalApproved = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UnitProcessApprovalApprovedType;
};

export type UnitProcessAcceptanceRejectedType = typeof UnitProcessAcceptanceRejectedType[keyof typeof UnitProcessAcceptanceRejectedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessAcceptanceRejectedType = {
  UnitProcessAcceptanceRejected: 'UnitProcessAcceptanceRejected',
} as const;

export type UnitProcessAcceptanceRejected = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UnitProcessAcceptanceRejectedType;
};

export type UnitProcessAcceptanceAcceptedType = typeof UnitProcessAcceptanceAcceptedType[keyof typeof UnitProcessAcceptanceAcceptedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessAcceptanceAcceptedType = {
  UnitProcessAcceptanceAccepted: 'UnitProcessAcceptanceAccepted',
} as const;

export type UnitProcessAcceptanceAccepted = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UnitProcessAcceptanceAcceptedType;
};

export type UnitProcessSignatureRejectedType = typeof UnitProcessSignatureRejectedType[keyof typeof UnitProcessSignatureRejectedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessSignatureRejectedType = {
  UnitProcessSignatureRejected: 'UnitProcessSignatureRejected',
} as const;

export type UnitProcessSignatureRejected = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UnitProcessSignatureRejectedType;
};

export type UnitProcessSignatureSignedType = typeof UnitProcessSignatureSignedType[keyof typeof UnitProcessSignatureSignedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessSignatureSignedType = {
  UnitProcessSignatureSigned: 'UnitProcessSignatureSigned',
} as const;

export type UnitProcessSignatureSigned = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UnitProcessSignatureSignedType;
};

export type VerificationResultHandledType = typeof VerificationResultHandledType[keyof typeof VerificationResultHandledType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationResultHandledType = {
  VerificationResultHandled: 'VerificationResultHandled',
} as const;

export type VerificationResultHandled = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: VerificationResultHandledType;
};

export type VerificationClosedType = typeof VerificationClosedType[keyof typeof VerificationClosedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationClosedType = {
  VerificationClosed: 'VerificationClosed',
} as const;

export type VerificationClosed = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: VerificationClosedType;
};

export type VerificationInitializedType = typeof VerificationInitializedType[keyof typeof VerificationInitializedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationInitializedType = {
  VerificationInitialized: 'VerificationInitialized',
} as const;

export type VerificationInitialized = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: VerificationInitializedType;
};

export type RaInvitedType = typeof RaInvitedType[keyof typeof RaInvitedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RaInvitedType = {
  RaInvited: 'RaInvited',
} as const;

export type RaInvited = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: RaInvitedType;
};

export type CrOnboardingRejectedType = typeof CrOnboardingRejectedType[keyof typeof CrOnboardingRejectedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrOnboardingRejectedType = {
  CrOnboardingRejected: 'CrOnboardingRejected',
} as const;

export type CrOnboardingRejected = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CrOnboardingRejectedType;
};

export type CrOnboardingRevokedType = typeof CrOnboardingRevokedType[keyof typeof CrOnboardingRevokedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrOnboardingRevokedType = {
  CrOnboardingRevoked: 'CrOnboardingRevoked',
} as const;

export type CrOnboardingRevoked = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CrOnboardingRevokedType;
};

export type CrOnboardingCreatedType = typeof CrOnboardingCreatedType[keyof typeof CrOnboardingCreatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrOnboardingCreatedType = {
  CrOnboardingCreated: 'CrOnboardingCreated',
} as const;

export type CrOnboardingCreated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CrOnboardingCreatedType;
};

export type GetSauronEventsResponseEventsItem = TicketCreated | CompanyUserRemovalSucceeded | CompanyUserBlockSucceeded | CompanyUserUnblockSucceeded | CompanyUpdated | CompanyClosed | CompanyBlocked | CompanyUnblocked | CompanyUpdateDocsRequested | CompanyAwaitingReview | CompanyActive | CompanyRemoved | CompanyMembershipAdded | CompanyMembershipChanged | UserRemovalSucceeded | UserBlocked | UserUnblocked | UserInfoUpdated | AccountRepRemovalSucceeded | AccountRepBlockSucceeded | AccountRepUnblockSucceeded | AccountUpdated | MembershipRemovalSucceeded | AccountClosed | AccountBlocked | AccountUnblocked | AccountMembershipAdded | MembershipRoleChanged | AccountCreated | TrustedRecipientAdded | TrustedRecipientRemoved | AccountUpdateRejected | AccountUpdateApproved | AccountCreationApproved | AccountCreationRejected | ApprovalCreated | ApprovalApproved | ApprovalClosed | ApprovalRejected | ArOnboardingCreated | ArOnboardingRevoked | ArOnboardingRejected | CrOnboardingCreated | CrOnboardingRevoked | CrOnboardingRejected | RaInvited | VerificationInitialized | VerificationClosed | VerificationResultHandled | UnitProcessSignatureSigned | UnitProcessSignatureRejected | UnitProcessAcceptanceAccepted | UnitProcessAcceptanceRejected | UnitProcessApprovalApproved | UnitProcessApprovalRejected | UnitProcessCancelled | UnitProcessInitiated | CompanyRemovalRequested;

export type ArOnboardingRejectedType = typeof ArOnboardingRejectedType[keyof typeof ArOnboardingRejectedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ArOnboardingRejectedType = {
  ArOnboardingRejected: 'ArOnboardingRejected',
} as const;

export type ArOnboardingRejected = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: ArOnboardingRejectedType;
};

export type ArOnboardingRevokedType = typeof ArOnboardingRevokedType[keyof typeof ArOnboardingRevokedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ArOnboardingRevokedType = {
  ArOnboardingRevoked: 'ArOnboardingRevoked',
} as const;

export type ArOnboardingRevoked = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: ArOnboardingRevokedType;
};

export type ArOnboardingCreatedType = typeof ArOnboardingCreatedType[keyof typeof ArOnboardingCreatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ArOnboardingCreatedType = {
  ArOnboardingCreated: 'ArOnboardingCreated',
} as const;

export type ArOnboardingCreated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: ArOnboardingCreatedType;
};

export type ApprovalRejectedType = typeof ApprovalRejectedType[keyof typeof ApprovalRejectedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalRejectedType = {
  ApprovalRejected: 'ApprovalRejected',
} as const;

export type ApprovalRejected = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: ApprovalRejectedType;
};

export type ApprovalClosedType = typeof ApprovalClosedType[keyof typeof ApprovalClosedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalClosedType = {
  ApprovalClosed: 'ApprovalClosed',
} as const;

export type ApprovalClosed = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: ApprovalClosedType;
};

export type ApprovalApprovedType = typeof ApprovalApprovedType[keyof typeof ApprovalApprovedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalApprovedType = {
  ApprovalApproved: 'ApprovalApproved',
} as const;

export type ApprovalApproved = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: ApprovalApprovedType;
};

export type ApprovalCreatedType = typeof ApprovalCreatedType[keyof typeof ApprovalCreatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalCreatedType = {
  ApprovalCreated: 'ApprovalCreated',
} as const;

export type ApprovalCreated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: ApprovalCreatedType;
};

export type AccountCreationRejectedType = typeof AccountCreationRejectedType[keyof typeof AccountCreationRejectedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountCreationRejectedType = {
  AccountCreationRejected: 'AccountCreationRejected',
} as const;

export type AccountCreationRejected = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountCreationRejectedType;
};

export type AccountCreationApprovedType = typeof AccountCreationApprovedType[keyof typeof AccountCreationApprovedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountCreationApprovedType = {
  AccountCreationApproved: 'AccountCreationApproved',
} as const;

export type AccountCreationApproved = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountCreationApprovedType;
};

export type AccountUpdateApprovedType = typeof AccountUpdateApprovedType[keyof typeof AccountUpdateApprovedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountUpdateApprovedType = {
  AccountUpdateApproved: 'AccountUpdateApproved',
} as const;

export type AccountUpdateApproved = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountUpdateApprovedType;
};

export type AccountUpdateRejectedType = typeof AccountUpdateRejectedType[keyof typeof AccountUpdateRejectedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountUpdateRejectedType = {
  AccountUpdateRejected: 'AccountUpdateRejected',
} as const;

export type AccountUpdateRejected = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountUpdateRejectedType;
};

export type TrustedRecipientRemovedType = typeof TrustedRecipientRemovedType[keyof typeof TrustedRecipientRemovedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrustedRecipientRemovedType = {
  TrustedRecipientRemoved: 'TrustedRecipientRemoved',
} as const;

export type TrustedRecipientRemoved = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: TrustedRecipientRemovedType;
};

export type TrustedRecipientAddedType = typeof TrustedRecipientAddedType[keyof typeof TrustedRecipientAddedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrustedRecipientAddedType = {
  TrustedRecipientAdded: 'TrustedRecipientAdded',
} as const;

export type TrustedRecipientAdded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: TrustedRecipientAddedType;
};

export type AccountCreatedType = typeof AccountCreatedType[keyof typeof AccountCreatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountCreatedType = {
  AccountCreated: 'AccountCreated',
} as const;

export type AccountCreated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountCreatedType;
};

export type MembershipRoleChangedType = typeof MembershipRoleChangedType[keyof typeof MembershipRoleChangedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipRoleChangedType = {
  MembershipRoleChanged: 'MembershipRoleChanged',
} as const;

export type MembershipRoleChanged = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: MembershipRoleChangedType;
};

export type AccountMembershipAddedType = typeof AccountMembershipAddedType[keyof typeof AccountMembershipAddedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountMembershipAddedType = {
  AccountMembershipAdded: 'AccountMembershipAdded',
} as const;

export type AccountMembershipAdded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountMembershipAddedType;
};

export type AccountUnblockedType = typeof AccountUnblockedType[keyof typeof AccountUnblockedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountUnblockedType = {
  AccountUnblocked: 'AccountUnblocked',
} as const;

export type AccountUnblocked = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountUnblockedType;
};

export type AccountBlockedType = typeof AccountBlockedType[keyof typeof AccountBlockedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountBlockedType = {
  AccountBlocked: 'AccountBlocked',
} as const;

export type AccountBlocked = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountBlockedType;
};

export type AccountClosedType = typeof AccountClosedType[keyof typeof AccountClosedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountClosedType = {
  AccountClosed: 'AccountClosed',
} as const;

export type AccountClosed = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountClosedType;
};

export type MembershipRemovalSucceededType = typeof MembershipRemovalSucceededType[keyof typeof MembershipRemovalSucceededType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipRemovalSucceededType = {
  MembershipRemovalSucceeded: 'MembershipRemovalSucceeded',
} as const;

export type MembershipRemovalSucceeded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: MembershipRemovalSucceededType;
};

export type AccountUpdatedType = typeof AccountUpdatedType[keyof typeof AccountUpdatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountUpdatedType = {
  AccountUpdated: 'AccountUpdated',
} as const;

export type AccountUpdated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountUpdatedType;
};

export type AccountRepUnblockSucceededType = typeof AccountRepUnblockSucceededType[keyof typeof AccountRepUnblockSucceededType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountRepUnblockSucceededType = {
  AccountRepUnblockSucceeded: 'AccountRepUnblockSucceeded',
} as const;

export type AccountRepUnblockSucceeded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountRepUnblockSucceededType;
};

export type AccountRepBlockSucceededType = typeof AccountRepBlockSucceededType[keyof typeof AccountRepBlockSucceededType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountRepBlockSucceededType = {
  AccountRepBlockSucceeded: 'AccountRepBlockSucceeded',
} as const;

export type AccountRepBlockSucceeded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountRepBlockSucceededType;
};

export type AccountRepRemovalSucceededType = typeof AccountRepRemovalSucceededType[keyof typeof AccountRepRemovalSucceededType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountRepRemovalSucceededType = {
  AccountRepRemovalSucceeded: 'AccountRepRemovalSucceeded',
} as const;

export type AccountRepRemovalSucceeded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: AccountRepRemovalSucceededType;
};

export type UserInfoUpdatedType = typeof UserInfoUpdatedType[keyof typeof UserInfoUpdatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserInfoUpdatedType = {
  UserInfoUpdated: 'UserInfoUpdated',
} as const;

export type UserInfoUpdated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UserInfoUpdatedType;
};

export type UserUnblockedType = typeof UserUnblockedType[keyof typeof UserUnblockedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserUnblockedType = {
  UserUnblocked: 'UserUnblocked',
} as const;

export type UserUnblocked = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UserUnblockedType;
};

export type UserBlockedType = typeof UserBlockedType[keyof typeof UserBlockedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserBlockedType = {
  UserBlocked: 'UserBlocked',
} as const;

export type UserBlocked = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UserBlockedType;
};

export type UserRemovalSucceededType = typeof UserRemovalSucceededType[keyof typeof UserRemovalSucceededType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRemovalSucceededType = {
  UserRemovalSucceeded: 'UserRemovalSucceeded',
} as const;

export type UserRemovalSucceeded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: UserRemovalSucceededType;
};

export type CompanyMembershipChangedType = typeof CompanyMembershipChangedType[keyof typeof CompanyMembershipChangedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyMembershipChangedType = {
  CompanyMembershipChanged: 'CompanyMembershipChanged',
} as const;

export type CompanyMembershipChanged = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyMembershipChangedType;
};

export type CompanyMembershipAddedType = typeof CompanyMembershipAddedType[keyof typeof CompanyMembershipAddedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyMembershipAddedType = {
  CompanyMembershipAdded: 'CompanyMembershipAdded',
} as const;

export type CompanyMembershipAdded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyMembershipAddedType;
};

export type CompanyRemovedType = typeof CompanyRemovedType[keyof typeof CompanyRemovedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRemovedType = {
  CompanyRemoved: 'CompanyRemoved',
} as const;

export type CompanyRemoved = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyRemovedType;
};

export type CompanyActiveType = typeof CompanyActiveType[keyof typeof CompanyActiveType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyActiveType = {
  CompanyActive: 'CompanyActive',
} as const;

export type CompanyActive = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyActiveType;
};

export type CompanyAwaitingReviewType = typeof CompanyAwaitingReviewType[keyof typeof CompanyAwaitingReviewType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyAwaitingReviewType = {
  CompanyAwaitingReview: 'CompanyAwaitingReview',
} as const;

export type CompanyAwaitingReview = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyAwaitingReviewType;
};

export type CompanyUpdateDocsRequestedType = typeof CompanyUpdateDocsRequestedType[keyof typeof CompanyUpdateDocsRequestedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUpdateDocsRequestedType = {
  CompanyUpdateDocsRequested: 'CompanyUpdateDocsRequested',
} as const;

export type CompanyUpdateDocsRequested = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyUpdateDocsRequestedType;
};

export type CompanyUnblockedType = typeof CompanyUnblockedType[keyof typeof CompanyUnblockedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUnblockedType = {
  CompanyUnblocked: 'CompanyUnblocked',
} as const;

export type CompanyUnblocked = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyUnblockedType;
};

export type CompanyBlockedType = typeof CompanyBlockedType[keyof typeof CompanyBlockedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyBlockedType = {
  CompanyBlocked: 'CompanyBlocked',
} as const;

export type CompanyBlocked = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyBlockedType;
};

export type CompanyClosedType = typeof CompanyClosedType[keyof typeof CompanyClosedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyClosedType = {
  CompanyClosed: 'CompanyClosed',
} as const;

export type CompanyClosed = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyClosedType;
};

export type CompanyUpdatedType = typeof CompanyUpdatedType[keyof typeof CompanyUpdatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUpdatedType = {
  CompanyUpdated: 'CompanyUpdated',
} as const;

export type CompanyUpdated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyUpdatedType;
};

export type CompanyUserUnblockSucceededType = typeof CompanyUserUnblockSucceededType[keyof typeof CompanyUserUnblockSucceededType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserUnblockSucceededType = {
  CompanyUserUnblockSucceeded: 'CompanyUserUnblockSucceeded',
} as const;

export type CompanyUserUnblockSucceeded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyUserUnblockSucceededType;
};

export type CompanyUserBlockSucceededType = typeof CompanyUserBlockSucceededType[keyof typeof CompanyUserBlockSucceededType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserBlockSucceededType = {
  CompanyUserBlockSucceeded: 'CompanyUserBlockSucceeded',
} as const;

export type CompanyUserBlockSucceeded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyUserBlockSucceededType;
};

export type CompanyUserRemovalSucceededType = typeof CompanyUserRemovalSucceededType[keyof typeof CompanyUserRemovalSucceededType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserRemovalSucceededType = {
  CompanyUserRemovalSucceeded: 'CompanyUserRemovalSucceeded',
} as const;

export type CompanyUserRemovalSucceeded = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: CompanyUserRemovalSucceededType;
};

export type TicketCreatedType = typeof TicketCreatedType[keyof typeof TicketCreatedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketCreatedType = {
  TicketCreated: 'TicketCreated',
} as const;

export type TicketCreated = {
  affectedAccountCompanyId?: string;
  affectedAccountCompanyName?: string;
  affectedAccountId?: string;
  affectedAccountName?: string;
  affectedCompanyId?: string;
  affectedCompanyName?: string;
  affectedProcessId?: string;
  affectedUnitId?: string;
  affectedUserId?: string;
  affectedUserName?: string;
  date: string;
  eventName: string;
  id: number;
  triggerRegistryAdmin?: boolean;
  triggerUserId?: string;
  triggerUserName?: string;
  type: TicketCreatedType;
};

export type ClaimAcceptanceAcceptRequestClaimPurpose = typeof ClaimAcceptanceAcceptRequestClaimPurpose[keyof typeof ClaimAcceptanceAcceptRequestClaimPurpose];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimAcceptanceAcceptRequestClaimPurpose = {
  international: 'international',
  domestic: 'domestic',
} as const;

export type ClaimAcceptanceAcceptRequest = {
  claimPurpose: ClaimAcceptanceAcceptRequestClaimPurpose;
};

export type OperationResponse = {
  operationId: string;
};

export type ProcessExportRequest = {
  fields: ExportRequestField[];
  fileName?: string;
  processIds: string[];
};

export type GetUnitProcessByIdResponseProcess = IssueProcessDTO | TransferProcessDTO | ClaimProcessDTO | IssueNewTreeProcessDTO | SplitProcessDTO;

export type GetUnitProcessByIdResponse = {
  process: GetUnitProcessByIdResponseProcess;
};

export type QueryUnitProcessState = typeof QueryUnitProcessState[keyof typeof QueryUnitProcessState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryUnitProcessState = {
  pending: 'pending',
  finished: 'finished',
} as const;

export type CreateTicketRequestType = typeof CreateTicketRequestType[keyof typeof CreateTicketRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateTicketRequestType = {
  technicalIssue: 'technicalIssue',
  otherIssues: 'otherIssues',
  suggestion: 'suggestion',
} as const;

export type CreateTicketRequest = {
  description: string;
  screenshots: Blob[];
  type: CreateTicketRequestType;
  url: string;
};

export type UnblockUnitRequest = {
  reason?: string;
};

export type BlockUnitRequest = {
  reason?: string;
};

export type Object = { [key: string]: any };

export type ExportRequestUnitType = typeof ExportRequestUnitType[keyof typeof ExportRequestUnitType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportRequestUnitType = {
  SAFcA: 'SAFcA',
  SAFcE: 'SAFcE',
} as const;

export type ExportRequestField = {
  name: string;
  path: string;
};

export type ExportRequest = {
  fields?: ExportRequestField[];
  fileName?: string;
  history?: boolean;
  ownerIds: string[];
  type?: UnitType;
  unitIds?: string[];
  unitType?: ExportRequestUnitType;
};

export type UnitResponseMetadata = { [key: string]: any };

export type UnitResponseComputed = { [key: string]: any };

export type UnitResponse = {
  computed: UnitResponseComputed;
  id: string;
  isLast: MaybeCensoredIsLast;
  lockedVolume: number;
  metadata: UnitResponseMetadata;
  ownerId: string;
  rootId: string;
  status: UnitStatus;
  type: UnitType;
  volume: number;
};

export type AddRegistryAdminRequest = {
  emails: string[];
};

export type UserResponse = {
  email: string;
  id: string;
  name: string;
};

export type CloseRequest = {
  reason: string;
};

export type RejectRequest = {
  reason: string;
};

export type ApproveRequestPayload = JoinCrApprovalRequestApproveRequest | CrDataApprovalRequestApproveRequest | CompanyCreateRequestApproveRequest | AccountDetailsChangeRequestApproveRequest | CompanyOnboardRequestApproveRequest | CompanyCloseRequestApproveRequest | AccountCreateRequestApproveRequest | CompanyUpdateDocsRequestApproveRequest | CompanyUpdateRequestApproveRequest | JoinArApprovalRequestApproveRequest | IssueApprovalRequestApproveRequest;

export type ApproveRequest = {
  payload: ApproveRequestPayload;
};

export type IssueApprovalRequestApproveRequestType = typeof IssueApprovalRequestApproveRequestType[keyof typeof IssueApprovalRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueApprovalRequestApproveRequestType = {
  IssueApprovalRequest: 'IssueApprovalRequest',
} as const;

export type IssueApprovalRequestApproveRequestMetadata = { [key: string]: any };

export type IssueApprovalRequestApproveRequest = {
  metadata: IssueApprovalRequestApproveRequestMetadata;
  type: IssueApprovalRequestApproveRequestType;
};

export type JoinArApprovalRequestApproveRequestType = typeof JoinArApprovalRequestApproveRequestType[keyof typeof JoinArApprovalRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JoinArApprovalRequestApproveRequestType = {
  JoinArApprovalRequest: 'JoinArApprovalRequest',
} as const;

export type JoinArApprovalRequestApproveRequest = {
  type: JoinArApprovalRequestApproveRequestType;
};

export type CompanyUpdateRequestApproveRequestType = typeof CompanyUpdateRequestApproveRequestType[keyof typeof CompanyUpdateRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUpdateRequestApproveRequestType = {
  CompanyUpdateRequest: 'CompanyUpdateRequest',
} as const;

export type CompanyUpdateRequestApproveRequest = {
  type: CompanyUpdateRequestApproveRequestType;
};

export type CompanyUpdateDocsRequestApproveRequestType = typeof CompanyUpdateDocsRequestApproveRequestType[keyof typeof CompanyUpdateDocsRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUpdateDocsRequestApproveRequestType = {
  CompanyUpdateDocsRequest: 'CompanyUpdateDocsRequest',
} as const;

export type CompanyUpdateDocsRequestApproveRequest = {
  type: CompanyUpdateDocsRequestApproveRequestType;
};

export type AccountCreateRequestApproveRequestType = typeof AccountCreateRequestApproveRequestType[keyof typeof AccountCreateRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountCreateRequestApproveRequestType = {
  AccountCreateRequest: 'AccountCreateRequest',
} as const;

export type AccountCreateRequestApproveRequest = {
  type: AccountCreateRequestApproveRequestType;
};

export type CompanyCloseRequestApproveRequestType = typeof CompanyCloseRequestApproveRequestType[keyof typeof CompanyCloseRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCloseRequestApproveRequestType = {
  CompanyCloseRequest: 'CompanyCloseRequest',
} as const;

export type CompanyCloseRequestApproveRequest = {
  type: CompanyCloseRequestApproveRequestType;
};

export type CompanyOnboardRequestApproveRequestType = typeof CompanyOnboardRequestApproveRequestType[keyof typeof CompanyOnboardRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOnboardRequestApproveRequestType = {
  CompanyOnboardRequest: 'CompanyOnboardRequest',
} as const;

export type CompanyOnboardRequestApproveRequest = {
  type: CompanyOnboardRequestApproveRequestType;
};

export type AccountDetailsChangeRequestApproveRequestType = typeof AccountDetailsChangeRequestApproveRequestType[keyof typeof AccountDetailsChangeRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountDetailsChangeRequestApproveRequestType = {
  AccountDetailsChangeRequest: 'AccountDetailsChangeRequest',
} as const;

export type AccountDetailsChangeRequestApproveRequest = {
  type: AccountDetailsChangeRequestApproveRequestType;
};

export type CompanyCreateRequestApproveRequestType = typeof CompanyCreateRequestApproveRequestType[keyof typeof CompanyCreateRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCreateRequestApproveRequestType = {
  CompanyCreateRequest: 'CompanyCreateRequest',
} as const;

export type CompanyCreateRequestApproveRequest = {
  type: CompanyCreateRequestApproveRequestType;
};

export type CrDataApprovalRequestApproveRequestType = typeof CrDataApprovalRequestApproveRequestType[keyof typeof CrDataApprovalRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrDataApprovalRequestApproveRequestType = {
  CrDataApprovalRequest: 'CrDataApprovalRequest',
} as const;

export type CrDataApprovalRequestApproveRequest = {
  type: CrDataApprovalRequestApproveRequestType;
};

export type JoinCrApprovalRequestApproveRequestType = typeof JoinCrApprovalRequestApproveRequestType[keyof typeof JoinCrApprovalRequestApproveRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JoinCrApprovalRequestApproveRequestType = {
  JoinCrApprovalRequest: 'JoinCrApprovalRequest',
} as const;

export type JoinCrApprovalRequestApproveRequest = {
  type: JoinCrApprovalRequestApproveRequestType;
};

export type GetWithPayloadResponseApproval = JoinCrApprovalRequestWithPayload | CrDataApprovalRequestWithPayload | CompanyCreateRequestWithPayload | AccountDetailsChangeRequestWithPayload | CompanyOnboardRequestWithPayload | CompanyCloseRequestWithPayload | AccountCreateRequestWithPayload | CompanyUpdateDocsRequestWithPayload | CompanyUpdateRequestWithPayload | JoinArApprovalRequestWithPayload | IssueApprovalRequestWithPayload;

export type GetWithPayloadResponse = {
  approval: GetWithPayloadResponseApproval;
};

export type IssueApprovalRequestWithPayloadType = typeof IssueApprovalRequestWithPayloadType[keyof typeof IssueApprovalRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueApprovalRequestWithPayloadType = {
  IssueApprovalRequest: 'IssueApprovalRequest',
} as const;

export type IssueApprovalRequestWithPayloadStateChangedReason = { [key: string]: any };

export type IssueApprovalRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: IssueApprovalPayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: IssueApprovalRequestWithPayloadStateChangedReason;
  type: IssueApprovalRequestWithPayloadType;
};

export type IssueApprovalPayloadMetadata = { [key: string]: any };

export type IssueApprovalPayload = {
  metadata: IssueApprovalPayloadMetadata;
  processId: string;
};

export type JoinArApprovalRequestWithPayloadType = typeof JoinArApprovalRequestWithPayloadType[keyof typeof JoinArApprovalRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JoinArApprovalRequestWithPayloadType = {
  JoinArApprovalRequest: 'JoinArApprovalRequest',
} as const;

export type JoinArApprovalRequestWithPayloadStateChangedReason = { [key: string]: any };

export type JoinArApprovalRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: JoinArApprovalRequestPayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: JoinArApprovalRequestWithPayloadStateChangedReason;
  type: JoinArApprovalRequestWithPayloadType;
};

export type AccountMembership = {
  accountId: string;
  accountMembershipRole: AccountMembershipRole;
  accountName: string;
};

export type JoinArApprovalRequestPayload = {
  accountsMembership: AccountMembership[];
  userDetails: UserDetailsDTO;
  userEmail: string;
  userId: string;
};

export type CompanyUpdateRequestWithPayloadType = typeof CompanyUpdateRequestWithPayloadType[keyof typeof CompanyUpdateRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUpdateRequestWithPayloadType = {
  CompanyUpdateRequest: 'CompanyUpdateRequest',
} as const;

export type CompanyUpdateRequestWithPayloadStateChangedReason = { [key: string]: any };

export type Country = { [key: string]: any };

export type CompanyUpdateDetailsPayload = {
  address: string;
  address2: string;
  address3: string;
  city: string;
  country: Country;
  name: string;
  postcode: string;
  state: string;
  website: string;
};

export type CompanyUpdatePayload = {
  companyId: string;
  details: CompanyUpdateDetailsPayload;
};

export type CompanyUpdateRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: CompanyUpdatePayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyUpdateRequestWithPayloadStateChangedReason;
  type: CompanyUpdateRequestWithPayloadType;
};

export type CompanyUpdateDocsRequestWithPayloadType = typeof CompanyUpdateDocsRequestWithPayloadType[keyof typeof CompanyUpdateDocsRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUpdateDocsRequestWithPayloadType = {
  CompanyUpdateDocsRequest: 'CompanyUpdateDocsRequest',
} as const;

export type CompanyUpdateDocsRequestWithPayloadStateChangedReason = { [key: string]: any };

export type CompanyUpdateDocsPayload = {
  companyId: string;
  uploads: string[];
};

export type CompanyUpdateDocsRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: CompanyUpdateDocsPayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyUpdateDocsRequestWithPayloadStateChangedReason;
  type: CompanyUpdateDocsRequestWithPayloadType;
};

export type AccountCreateRequestWithPayloadType = typeof AccountCreateRequestWithPayloadType[keyof typeof AccountCreateRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountCreateRequestWithPayloadType = {
  AccountCreateRequest: 'AccountCreateRequest',
} as const;

export type AccountCreateRequestWithPayloadStateChangedReason = { [key: string]: any };

export type AccountCreatePayload = {
  id: string;
};

export type AccountCreateRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: AccountCreatePayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: AccountCreateRequestWithPayloadStateChangedReason;
  type: AccountCreateRequestWithPayloadType;
};

export type CompanyCloseRequestWithPayloadType = typeof CompanyCloseRequestWithPayloadType[keyof typeof CompanyCloseRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCloseRequestWithPayloadType = {
  CompanyCloseRequest: 'CompanyCloseRequest',
} as const;

export type CompanyCloseRequestWithPayloadStateChangedReason = { [key: string]: any };

export type CompanyClosePayload = {
  companyId: string;
};

export type CompanyCloseRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: CompanyClosePayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyCloseRequestWithPayloadStateChangedReason;
  type: CompanyCloseRequestWithPayloadType;
};

export type CompanyOnboardRequestWithPayloadType = typeof CompanyOnboardRequestWithPayloadType[keyof typeof CompanyOnboardRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOnboardRequestWithPayloadType = {
  CompanyOnboardRequest: 'CompanyOnboardRequest',
} as const;

export type CompanyOnboardRequestWithPayloadStateChangedReason = { [key: string]: any };

export type CompanyOnboardingApprovalPayload = {
  companyDetails: CompanyDetailsResponse;
  isVerificationPositive: boolean;
  userDetails: UserDetailsDTO;
  userEmail: string;
  userId: string;
  verificationApplicantUrl: string;
};

export type CompanyOnboardRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: CompanyOnboardingApprovalPayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyOnboardRequestWithPayloadStateChangedReason;
  type: CompanyOnboardRequestWithPayloadType;
};

export type AccountDetailsChangeRequestWithPayloadType = typeof AccountDetailsChangeRequestWithPayloadType[keyof typeof AccountDetailsChangeRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountDetailsChangeRequestWithPayloadType = {
  AccountDetailsChangeRequest: 'AccountDetailsChangeRequest',
} as const;

export type AccountDetailsChangeRequestWithPayloadStateChangedReason = { [key: string]: any };

export type AccountDetailsChangePayloadType = typeof AccountDetailsChangePayloadType[keyof typeof AccountDetailsChangePayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountDetailsChangePayloadType = {
  airTransportProviderHoldingAccount: 'airTransportProviderHoldingAccount',
  certificationBody: 'certificationBody',
  fuelProviderHoldingAccount: 'fuelProviderHoldingAccount',
  generalHoldingAccount: 'generalHoldingAccount',
  logisticsProviderHoldingAccount: 'logisticsProviderHoldingAccount',
} as const;

export type AccountDetailsChangePayload = {
  documents: string[];
  id: string;
  name: string;
  type: AccountDetailsChangePayloadType;
};

export type AccountDetailsChangeRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: AccountDetailsChangePayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: AccountDetailsChangeRequestWithPayloadStateChangedReason;
  type: AccountDetailsChangeRequestWithPayloadType;
};

export type CompanyCreateRequestWithPayloadType = typeof CompanyCreateRequestWithPayloadType[keyof typeof CompanyCreateRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCreateRequestWithPayloadType = {
  CompanyCreateRequest: 'CompanyCreateRequest',
} as const;

export type CompanyCreateRequestWithPayloadStateChangedReason = { [key: string]: any };

export type CompanyCreatePayload = {
  companyDetails: CompanyDetailsResponse;
  userDetails: UserDetailsDTO;
  userEmail: string;
  userId: string;
};

export type CompanyCreateRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: CompanyCreatePayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyCreateRequestWithPayloadStateChangedReason;
  type: CompanyCreateRequestWithPayloadType;
};

export type CrDataApprovalRequestWithPayloadType = typeof CrDataApprovalRequestWithPayloadType[keyof typeof CrDataApprovalRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrDataApprovalRequestWithPayloadType = {
  CrDataApprovalRequest: 'CrDataApprovalRequest',
} as const;

export type CrDataApprovalRequestWithPayloadStateChangedReason = { [key: string]: any };

export type CrDataApprovalRequestPayload = {
  companies: CrApprovalRequestCompanyPayload[];
  userDetails: UserDetailsDTO;
  userId: string;
};

export type CrDataApprovalRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: CrDataApprovalRequestPayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CrDataApprovalRequestWithPayloadStateChangedReason;
  type: CrDataApprovalRequestWithPayloadType;
};

export type JoinCrApprovalRequestWithPayloadType = typeof JoinCrApprovalRequestWithPayloadType[keyof typeof JoinCrApprovalRequestWithPayloadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JoinCrApprovalRequestWithPayloadType = {
  JoinCrApprovalRequest: 'JoinCrApprovalRequest',
} as const;

export type JoinCrApprovalRequestWithPayloadStateChangedReason = { [key: string]: any };

export type JoinCrApprovalRequestWithPayload = {
  createdAt: string;
  id: string;
  payload: JoinCrApprovalRequestPayload;
  state: string;
  stateChangedAt: string;
  stateChangedReason: JoinCrApprovalRequestWithPayloadStateChangedReason;
  type: JoinCrApprovalRequestWithPayloadType;
};

export type CrApprovalRequestCompanyPayload = {
  id: string;
  name: string;
};

export type JoinCrApprovalRequestPayload = {
  companies: CrApprovalRequestCompanyPayload[];
  kycApplicantUrl: string;
  kycResult: boolean;
  userDetails: UserDetailsDTO;
  userEmail: string;
  userId: string;
};

export type ApprovalState = typeof ApprovalState[keyof typeof ApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalState = {
  approved: 'approved',
  pending: 'pending',
  rejected: 'rejected',
  closed: 'closed',
} as const;

export type ApprovalType = typeof ApprovalType[keyof typeof ApprovalType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalType = {
  JoinCrApprovalRequest: 'JoinCrApprovalRequest',
  CrDataApprovalRequest: 'CrDataApprovalRequest',
  CompanyCreateRequest: 'CompanyCreateRequest',
  AccountDetailsChangeRequest: 'AccountDetailsChangeRequest',
  CompanyOnboardRequest: 'CompanyOnboardRequest',
  CompanyCloseRequest: 'CompanyCloseRequest',
  AccountCreateRequest: 'AccountCreateRequest',
  CompanyUpdateDocsRequest: 'CompanyUpdateDocsRequest',
  CompanyUpdateRequest: 'CompanyUpdateRequest',
  JoinArApprovalRequest: 'JoinArApprovalRequest',
  IssueApprovalRequest: 'IssueApprovalRequest',
} as const;

export type CountryResponse = {
  code: string;
  name: string;
  phoneCode: string;
};

export type PendingApprovalRequestsApprovalRequestsItem = JoinCrApprovalRequest | CrDataApprovalRequest | CompanyCreateRequest | AccountDetailsChangeRequest | CompanyOnboardRequest | CompanyCloseRequest | AccountCreateRequest | CompanyUpdateDocsRequest | CompanyUpdateRequest | JoinArApprovalRequest | IssueApprovalRequest;

export type PendingApprovalRequests = {
  approvalRequests: PendingApprovalRequestsApprovalRequestsItem[];
};

export type GetPendingActionsResponse = {
  pendingAcceptance: QueryUnitProcessResponse;
  pendingApproval: PendingApprovalRequests;
  pendingSignature: QueryUnitProcessResponse;
};

export type IssueApprovalRequestType = typeof IssueApprovalRequestType[keyof typeof IssueApprovalRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueApprovalRequestType = {
  IssueApprovalRequest: 'IssueApprovalRequest',
} as const;

export type IssueApprovalRequestStateChangedReason = { [key: string]: any };

export type IssueApprovalRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: IssueApprovalRequestStateChangedReason;
  type: IssueApprovalRequestType;
};

export type JoinArApprovalRequestType = typeof JoinArApprovalRequestType[keyof typeof JoinArApprovalRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JoinArApprovalRequestType = {
  JoinArApprovalRequest: 'JoinArApprovalRequest',
} as const;

export type JoinArApprovalRequestStateChangedReason = { [key: string]: any };

export type JoinArApprovalRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: JoinArApprovalRequestStateChangedReason;
  type: JoinArApprovalRequestType;
};

export type CompanyUpdateRequestType = typeof CompanyUpdateRequestType[keyof typeof CompanyUpdateRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUpdateRequestType = {
  CompanyUpdateRequest: 'CompanyUpdateRequest',
} as const;

export type CompanyUpdateRequestStateChangedReason = { [key: string]: any };

export type CompanyUpdateRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyUpdateRequestStateChangedReason;
  type: CompanyUpdateRequestType;
};

export type CompanyUpdateDocsRequestType = typeof CompanyUpdateDocsRequestType[keyof typeof CompanyUpdateDocsRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUpdateDocsRequestType = {
  CompanyUpdateDocsRequest: 'CompanyUpdateDocsRequest',
} as const;

export type CompanyUpdateDocsRequestStateChangedReason = { [key: string]: any };

export type CompanyUpdateDocsRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyUpdateDocsRequestStateChangedReason;
  type: CompanyUpdateDocsRequestType;
};

export type AccountCreateRequestType = typeof AccountCreateRequestType[keyof typeof AccountCreateRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountCreateRequestType = {
  AccountCreateRequest: 'AccountCreateRequest',
} as const;

export type AccountCreateRequestStateChangedReason = { [key: string]: any };

export type AccountCreateRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: AccountCreateRequestStateChangedReason;
  type: AccountCreateRequestType;
};

export type CompanyCloseRequestType = typeof CompanyCloseRequestType[keyof typeof CompanyCloseRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCloseRequestType = {
  CompanyCloseRequest: 'CompanyCloseRequest',
} as const;

export type CompanyCloseRequestStateChangedReason = { [key: string]: any };

export type CompanyCloseRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyCloseRequestStateChangedReason;
  type: CompanyCloseRequestType;
};

export type CompanyOnboardRequestType = typeof CompanyOnboardRequestType[keyof typeof CompanyOnboardRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOnboardRequestType = {
  CompanyOnboardRequest: 'CompanyOnboardRequest',
} as const;

export type CompanyOnboardRequestStateChangedReason = { [key: string]: any };

export type CompanyOnboardRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyOnboardRequestStateChangedReason;
  type: CompanyOnboardRequestType;
};

export type AccountDetailsChangeRequestType = typeof AccountDetailsChangeRequestType[keyof typeof AccountDetailsChangeRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountDetailsChangeRequestType = {
  AccountDetailsChangeRequest: 'AccountDetailsChangeRequest',
} as const;

export type AccountDetailsChangeRequestStateChangedReason = { [key: string]: any };

export type AccountDetailsChangeRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: AccountDetailsChangeRequestStateChangedReason;
  type: AccountDetailsChangeRequestType;
};

export type CompanyCreateRequestType = typeof CompanyCreateRequestType[keyof typeof CompanyCreateRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCreateRequestType = {
  CompanyCreateRequest: 'CompanyCreateRequest',
} as const;

export type CompanyCreateRequestStateChangedReason = { [key: string]: any };

export type CompanyCreateRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CompanyCreateRequestStateChangedReason;
  type: CompanyCreateRequestType;
};

export type CrDataApprovalRequestType = typeof CrDataApprovalRequestType[keyof typeof CrDataApprovalRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrDataApprovalRequestType = {
  CrDataApprovalRequest: 'CrDataApprovalRequest',
} as const;

export type CrDataApprovalRequestStateChangedReason = { [key: string]: any };

export type CrDataApprovalRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: CrDataApprovalRequestStateChangedReason;
  type: CrDataApprovalRequestType;
};

export type JoinCrApprovalRequestType = typeof JoinCrApprovalRequestType[keyof typeof JoinCrApprovalRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JoinCrApprovalRequestType = {
  JoinCrApprovalRequest: 'JoinCrApprovalRequest',
} as const;

export type JoinCrApprovalRequestStateChangedReason = { [key: string]: any };

export type JoinCrApprovalRequest = {
  createdAt: string;
  id: string;
  state: string;
  stateChangedAt: string;
  stateChangedReason: JoinCrApprovalRequestStateChangedReason;
  type: JoinCrApprovalRequestType;
};

export type QueryUnitProcessResponse = {
  processes: QueryUnitProcessResponseProcessesItem[];
};

export type SplitProcessDTOMetadata = typeof SplitProcessDTOMetadata[keyof typeof SplitProcessDTOMetadata];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SplitProcessDTOMetadata = {
} as const;

export type SplitProcessDTOComputed = { [key: string]: any };

export type SplitProcessState = typeof SplitProcessState[keyof typeof SplitProcessState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SplitProcessState = {
  pendingSplit: 'pendingSplit',
  splitted: 'splitted',
  removed: 'removed',
} as const;

export type SplitProcessDTO = {
  actor: UnitProcessActorDTO;
  blockId: string;
  computed: SplitProcessDTOComputed;
  createdAt: string;
  id: string;
  metadata: SplitProcessDTOMetadata;
  processExpirationDate: string | null;
  receivingAccountId: string;
  receivingAccountName: string;
  receivingCompanyId: string;
  receivingCompanyName: string;
  sendingAccountId: string;
  sendingAccountName: string;
  sendingCompanyId: string;
  sendingCompanyName: string;
  state: SplitProcessState;
  type: string;
  unit: UnitDTO;
  volume: number;
};

export type IssueNewTreeProcessDTOMetadata = { [key: string]: any };

export type IssueNewTreeProcessDTOComputed = { [key: string]: any };

export type IssueNewTreeProcessState = typeof IssueNewTreeProcessState[keyof typeof IssueNewTreeProcessState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueNewTreeProcessState = {
  start: 'start',
  pendingSignaturesNotification: 'pendingSignaturesNotification',
  pendingSignatures: 'pendingSignatures',
  signaturesRejected: 'signaturesRejected',
  signaturesExpired: 'signaturesExpired',
  pendingIssuance: 'pendingIssuance',
  issued: 'issued',
  pendingParticipantsNotification: 'pendingParticipantsNotification',
  cancelled: 'cancelled',
  removed: 'removed',
} as const;

export type IssueNewTreeProcessDTO = {
  actor: UnitProcessActorDTO;
  computed: IssueNewTreeProcessDTOComputed;
  createdAt: string;
  genesisBlockId: string;
  id: string;
  metadata: IssueNewTreeProcessDTOMetadata;
  minimalSignaturesNumber: number;
  processExpirationDate: string | null;
  receivingAccountId: string;
  receivingAccountName: string;
  receivingCompanyId: string;
  receivingCompanyName: string;
  sendingAccountId: string;
  sendingAccountName: string;
  sendingCompanyId: string;
  sendingCompanyName: string;
  signatures: SignaturesResponse[];
  state: IssueNewTreeProcessState;
  type: string;
  unit: UnitDTO;
  volume: number;
};

export type ClaimProcessDTOMetadata = { [key: string]: any };

export type ClaimProcessDTOComputed = { [key: string]: any };

export type ClaimProcessState = typeof ClaimProcessState[keyof typeof ClaimProcessState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimProcessState = {
  pendingAccountType: 'pendingAccountType',
  pendingVolume: 'pendingVolume',
  pendingRetirementYear: 'pendingRetirementYear',
  pendingRetirementCountry: 'pendingRetirementCountry',
  pendingSafcBeneficiary: 'pendingSafcBeneficiary',
  pendingClaimPurpose: 'pendingClaimPurpose',
  safcTypeFlowDecision: 'safcTypeFlowDecision',
  pendingComplianceRegime: 'pendingComplianceRegime',
  pendingSercBeneficiary: 'pendingSercBeneficiary',
  pendingDecisionProcess: 'pendingDecisionProcess',
  pendingSignaturesNotification: 'pendingSignaturesNotification',
  pendingSignatures: 'pendingSignatures',
  signaturesRejected: 'signaturesRejected',
  signaturesExpired: 'signaturesExpired',
  pendingAcceptance: 'pendingAcceptance',
  acceptanceRejected: 'acceptanceRejected',
  acceptanceExpired: 'acceptanceExpired',
  operationDecision: 'operationDecision',
  pendingSercIssuance: 'pendingSercIssuance',
  pendingClaim: 'pendingClaim',
  notificationFlowDecision: 'notificationFlowDecision',
  pendingExternalBeneficiaryNotification: 'pendingExternalBeneficiaryNotification',
  claimed: 'claimed',
  cancelled: 'cancelled',
  removed: 'removed',
} as const;

export type ClaimProcessDTO = {
  actor: UnitProcessActorDTO;
  blockId: string;
  computed: ClaimProcessDTOComputed;
  createdAt: string;
  id: string;
  metadata: ClaimProcessDTOMetadata;
  minimalSignaturesNumber: number;
  processExpirationDate: string | null;
  receivingAccountId: string;
  receivingAccountName: string;
  receivingCompanyId: string;
  receivingCompanyName: string;
  safcClaimedUnitId?: string;
  safcRemainingUnitId?: string;
  sendingAccountId: string;
  sendingAccountName: string;
  sendingCompanyId: string;
  sendingCompanyName: string;
  sercClaimedUnitId?: string;
  sercIssuedUnitId?: string;
  sercRemainingUnitId?: string;
  signatures: SignaturesResponse[];
  state: ClaimProcessState;
  type: string;
  unit: UnitDTO;
  volume: number;
};

export type QueryUnitProcessResponseProcessesItem = IssueProcessDTO | TransferProcessDTO | ClaimProcessDTO | IssueNewTreeProcessDTO | SplitProcessDTO;

export type TransferProcessDTOMetadata = { [key: string]: any };

export type TransferProcessDTOComputed = { [key: string]: any };

export type TransferProcessState = typeof TransferProcessState[keyof typeof TransferProcessState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferProcessState = {
  transferStarted: 'transferStarted',
  pendingSignaturesNotification: 'pendingSignaturesNotification',
  pendingSignatures: 'pendingSignatures',
  signaturesRejected: 'signaturesRejected',
  signaturesExpired: 'signaturesExpired',
  pendingAcceptance: 'pendingAcceptance',
  acceptanceRejected: 'acceptanceRejected',
  acceptanceExpired: 'acceptanceExpired',
  pendingTransfer: 'pendingTransfer',
  transferred: 'transferred',
  cancelled: 'cancelled',
  removed: 'removed',
} as const;

export type TransferProcessDTO = {
  actor: UnitProcessActorDTO;
  blockId: string;
  computed: TransferProcessDTOComputed;
  createdAt: string;
  id: string;
  metadata: TransferProcessDTOMetadata;
  minimalSignaturesNumber: number;
  processExpirationDate: string | null;
  receivingAccountId: string;
  receivingAccountName: string;
  receivingCompanyId: string;
  receivingCompanyName: string;
  sendingAccountId: string;
  sendingAccountName: string;
  sendingCompanyId: string;
  sendingCompanyName: string;
  signatures: SignaturesResponse[];
  state: TransferProcessState;
  type: string;
  unit: UnitDTO;
  volume: number;
};

export type IssueProcessDTOMetadata = { [key: string]: any };

export type IssueProcessDTOComputed = { [key: string]: any };

export type UnitStatus = typeof UnitStatus[keyof typeof UnitStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitStatus = {
  active: 'active',
  blocked: 'blocked',
  retired: 'retired',
  removed: 'removed',
  expired: 'expired',
} as const;

export type MaybeCensoredStatus = {
  __censored: boolean;
  value: UnitStatus;
};

export type UnitType = typeof UnitType[keyof typeof UnitType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitType = {
  Issue: 'Issue',
  Claim: 'Claim',
  Split: 'Split',
  Transfer: 'Transfer',
} as const;

export type MaybeCensoredType = {
  __censored: boolean;
  value: UnitType;
};

export type MaybeCensoredMetadataValue = { [key: string]: any };

export type MaybeCensoredMetadata = {
  __censored: boolean;
  value: MaybeCensoredMetadataValue;
};

export type UnitDTO = {
  computed: MaybeCensoredComputed;
  /** ID field is always present, however if the unit was not created yet, the ID will be empty string */
  id: string;
  isLast: MaybeCensoredIsLast;
  metadata: MaybeCensoredMetadata;
  ownerId: MaybeCensoredOwnerId;
  /** Root ID field is always present, however if the unit was not created yet, the Root ID will be empty string */
  rootId: string;
  status: MaybeCensoredStatus;
  type: MaybeCensoredType;
  volume: number;
};

export type MaybeCensoredComputedValue = { [key: string]: any };

export type MaybeCensoredComputed = {
  __censored: boolean;
  value: MaybeCensoredComputedValue;
};

export type MaybeCensoredIsLast = {
  __censored: boolean;
  value: boolean;
};

export type MaybeCensoredOwnerId = {
  __censored: boolean;
  value: string;
};

export type SignaturesResponse = {
  accountId: string;
  userId: string;
};

export type IssueProcessState = typeof IssueProcessState[keyof typeof IssueProcessState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueProcessState = {
  issuanceStarted: 'issuanceStarted',
  pendingSignaturesNotification: 'pendingSignaturesNotification',
  pendingSignatures: 'pendingSignatures',
  signaturesRejected: 'signaturesRejected',
  signaturesExpired: 'signaturesExpired',
  pendingApprovalCreation: 'pendingApprovalCreation',
  pendingApproval: 'pendingApproval',
  approvalRejected: 'approvalRejected',
  pendingIssuance: 'pendingIssuance',
  pendingParticipantsNotification: 'pendingParticipantsNotification',
  issued: 'issued',
  cancelled: 'cancelled',
  removed: 'removed',
} as const;

export type UnitProcessActorDTOAccountType = typeof UnitProcessActorDTOAccountType[keyof typeof UnitProcessActorDTOAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitProcessActorDTOAccountType = {
  airTransportProviderHoldingAccount: 'airTransportProviderHoldingAccount',
  certificationBody: 'certificationBody',
  fuelProviderHoldingAccount: 'fuelProviderHoldingAccount',
  generalHoldingAccount: 'generalHoldingAccount',
  logisticsProviderHoldingAccount: 'logisticsProviderHoldingAccount',
} as const;

export type UnitProcessActorDTO = {
  accountId: string;
  accountName: string;
  accountType: UnitProcessActorDTOAccountType;
  userEmail: string;
  userId: string;
  userName: string;
};

export type IssueProcessDTO = {
  actor: UnitProcessActorDTO;
  approvalRequestId: string;
  computed: IssueProcessDTOComputed;
  createdAt: string;
  id: string;
  issuedBlockId: string;
  metadata: IssueProcessDTOMetadata;
  minimalSignaturesNumber: number;
  processExpirationDate: string | null;
  receivingAccountId: string;
  receivingAccountName: string;
  receivingCompanyId: string;
  receivingCompanyName: string;
  sendingAccountId: string;
  sendingAccountName: string;
  sendingCompanyId: string;
  sendingCompanyName: string;
  signatures: SignaturesResponse[];
  state: IssueProcessState;
  type: string;
  unit: UnitDTO;
  volume: number;
};

export type GetPendingActionsCountResponse = {
  pendingAcceptanceCount: number;
  pendingApprovalCount: number;
  pendingSignatureCount: number;
};

export type UserDetailsResponse = {
  details: UserDetailsDTO;
  email: string;
  id: string;
  name: string;
};

export type UserDataResponse = {
  accountMemberships: AccountMembershipResponse[];
  accounts: AccountResponse[];
  companies: UserDataCompanyItem[];
  email: string;
  id: string;
  isRegistryAdmin: boolean;
  name: string;
  status: UserStatus;
};

export type InvitedAccountMembershipDtoRole = typeof InvitedAccountMembershipDtoRole[keyof typeof InvitedAccountMembershipDtoRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvitedAccountMembershipDtoRole = {
  accountRepFullAccess: 'accountRepFullAccess',
  accountRepApprover: 'accountRepApprover',
  accountRepInitiator: 'accountRepInitiator',
  readOnly: 'readOnly',
} as const;

export type InvitedAccountMembershipDto = {
  accountId: string;
  role: InvitedAccountMembershipDtoRole;
};

export type GetAccountInvitedResponse = {
  accounts: InvitedAccountMembershipDto[];
  email: string;
};

export type GetCompanyInvitedResponse = {
  email: string;
  hasPendingApproval: boolean;
};

export type GetAccountUsersResponseStatus = typeof GetAccountUsersResponseStatus[keyof typeof GetAccountUsersResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAccountUsersResponseStatus = {
  active: 'active',
  blocked: 'blocked',
} as const;

export type GetAccountUsersResponseRole = typeof GetAccountUsersResponseRole[keyof typeof GetAccountUsersResponseRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAccountUsersResponseRole = {
  accountRepFullAccess: 'accountRepFullAccess',
  accountRepApprover: 'accountRepApprover',
  accountRepInitiator: 'accountRepInitiator',
  readOnly: 'readOnly',
} as const;

export type GetAccountUsersResponse = {
  email: string;
  id: string;
  name: string;
  role: GetAccountUsersResponseRole;
  status: GetAccountUsersResponseStatus;
};

export type CompanyUserDataItem = {
  accountMemberships: AccountMembershipResponse[];
  accounts: AccountResponse[];
  companies: UserDataCompanyItem[];
  email: string;
  id: string;
  isPromotingToCr: boolean;
  isRegistryAdmin: boolean;
  name: string;
  status: UserStatus;
};

export type UpdateUserDetailsItem = {
  employerName?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phoneNumber?: string;
};

export type UpdateUserRequest = {
  details?: UpdateUserDetailsItem;
  email?: string;
};

export type UserDataAccountInvitationItem = {
  id: string;
  name: string;
  role: string;
};

export type UserDataCompanyInvitationItem = {
  id: string;
  name: string;
  role: string;
};

export type UserListResponseItem = {
  accountInvitations: UserDataAccountInvitationItem[];
  accounts: UserDataAccountItem[];
  companies: UserDataCompanyItem[];
  companyInvitations: UserDataCompanyInvitationItem[];
  email: string;
  name: string;
  status: UserStatus;
  userId: string;
};

export type AccountMembershipStatus = typeof AccountMembershipStatus[keyof typeof AccountMembershipStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountMembershipStatus = {
  active: 'active',
  removed: 'removed',
} as const;

export type AccountMembershipResponseStatus = typeof AccountMembershipResponseStatus[keyof typeof AccountMembershipResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountMembershipResponseStatus = {
  active: 'active',
  removed: 'removed',
} as const;

export type AccountMembershipRole = typeof AccountMembershipRole[keyof typeof AccountMembershipRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountMembershipRole = {
  accountRepFullAccess: 'accountRepFullAccess',
  accountRepApprover: 'accountRepApprover',
  accountRepInitiator: 'accountRepInitiator',
  readOnly: 'readOnly',
} as const;

export type UserDataAccountItem = {
  id: string;
  membershipStatus: AccountMembershipStatus;
  name: string;
  role: AccountMembershipRole;
};

export type AccountMembershipResponse = {
  accountId: string;
  id: number;
  role: AccountMembershipRole;
  status: AccountMembershipResponseStatus;
  userId: string;
};

export type UserDataCompanyItemMembershipStatus = typeof UserDataCompanyItemMembershipStatus[keyof typeof UserDataCompanyItemMembershipStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDataCompanyItemMembershipStatus = {
  active: 'active',
  removed: 'removed',
} as const;

export type CompanyRole = typeof CompanyRole[keyof typeof CompanyRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRole = {
  companyRepresentative: 'companyRepresentative',
  regular: 'regular',
} as const;

export type UserDataCompanyItem = {
  id: string;
  membershipStatus: UserDataCompanyItemMembershipStatus;
  name: string;
  role: CompanyRole;
};

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatus = {
  active: 'active',
  blocked: 'blocked',
} as const;

export type UserDataWithOnboardingStatus = {
  accountMemberships: AccountMembershipResponse[];
  accounts: AccountResponse[];
  companies: UserDataCompanyItem[];
  email: string;
  id: string;
  isPendingOnboardingApproval: boolean;
  isRegistryAdmin: boolean;
  name: string;
  status: UserStatus;
};

export type CompanyDetailsDTO = {
  address: string;
  address2?: string;
  address3?: string;
  city: string;
  /** ISO2 country code */
  country: string;
  email: string;
  name: string;
  postcode: string;
  state?: string;
  website: string;
};

export type UserDetailsDTO = {
  employerName: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  phoneNumber: string;
};

export type SetUserCompanyDetailsRequest = {
  company: CompanyDetailsDTO;
  companyUploads: Blob[];
  user: UserDetailsDTO;
};

export type SetUserDetailsRequest = {
  user: UserDetailsDTO;
};

export type GetOnboardingResponseStatus = typeof GetOnboardingResponseStatus[keyof typeof GetOnboardingResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOnboardingResponseStatus = {
  pendingUserData: 'pendingUserData',
  pendingVerification: 'pendingVerification',
  pendingInternalAction: 'pendingInternalAction',
  onboarded: 'onboarded',
  failed: 'failed',
} as const;

export type GetOnboardingResponse = {
  status: GetOnboardingResponseStatus;
};

export type OnboardCompanyRequest = {
  email: string;
};

export type UpdatedInvoicePositionType = typeof UpdatedInvoicePositionType[keyof typeof UpdatedInvoicePositionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdatedInvoicePositionType = {
  registryFee: 'registryFee',
  issuance: 'issuance',
  transferSAFcA: 'transferSAFcA',
  transferSAFcE: 'transferSAFcE',
  retire: 'retire',
} as const;

export type UpdatedInvoicePosition = {
  description: string;
  /** Price in cents */
  price: number;
  quantity: number;
  type: UpdatedInvoicePositionType;
};

export type UpdateInvoicePayload = {
  description?: string;
  items: UpdatedInvoicePosition[];
};

export type InvoiceResponseStatus = typeof InvoiceResponseStatus[keyof typeof InvoiceResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceResponseStatus = {
  draft: 'draft',
  waitingForPayment: 'waitingForPayment',
  paid: 'paid',
} as const;

export type InvoiceActions = {
  finalize: boolean;
  pay: boolean;
};

export type InvoiceResponse = {
  actions: InvoiceActions;
  companyId: string;
  companyName: string;
  createdAt: string;
  from: string;
  id: string;
  isSynchronized: boolean;
  paymentLink: string | null;
  pdfLink: string | null;
  positions: InvoicePosition[];
  status: InvoiceResponseStatus;
  to: string;
  totalPrice: string;
};

export type InvoicePositionType = typeof InvoicePositionType[keyof typeof InvoicePositionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePositionType = {
  registryFee: 'registryFee',
  issuance: 'issuance',
  transferSAFcA: 'transferSAFcA',
  transferSAFcE: 'transferSAFcE',
  retire: 'retire',
} as const;

export type InvoicePosition = {
  description: string;
  price: string;
  quantity: number;
  type: InvoicePositionType;
  volume: number | null;
};

export type PaidServiceDto = {
  description?: string;
  paidServiceType: string;
  price: number;
};

export type UpdateAccountRequest = {
  accountData?: UpdateAccountRequestAccountData;
  name?: string;
  newDocuments?: Blob[];
  requiredSignatures?: number;
};

export type BlockAccountRequest = {
  reason?: string;
};

export type ChangeRoleRequest = {
  role: string;
};

export type RevokeARInvitationRequest = {
  email: string;
};

export type AddAccountRepRequest = {
  accountRepresentativesToJoin: NewAccountRepresentativeItem[];
};

export type AccountState = typeof AccountState[keyof typeof AccountState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountState = {
  awaitingReview: 'awaitingReview',
  active: 'active',
  blocked: 'blocked',
  closed: 'closed',
  removed: 'removed',
} as const;

export type AccountUser = {
  email: string;
  id: string;
  name: string;
};

export type AccountApiStatus = typeof AccountApiStatus[keyof typeof AccountApiStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountApiStatus = {
  awaitingReview: 'awaitingReview',
  active: 'active',
  blocked: 'blocked',
  closed: 'closed',
  rejected: 'rejected',
  removed: 'removed',
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountType = {
  airTransportProviderHoldingAccount: 'airTransportProviderHoldingAccount',
  certificationBody: 'certificationBody',
  fuelProviderHoldingAccount: 'fuelProviderHoldingAccount',
  generalHoldingAccount: 'generalHoldingAccount',
  logisticsProviderHoldingAccount: 'logisticsProviderHoldingAccount',
} as const;

export type AccountWithRoleResponse = {
  companyId: string;
  id: string;
  name: string;
  role: string;
  status: AccountState;
  type: AccountType;
};

export type AccountResponse = {
  blockReasons: AccountBlockReason[];
  companyId: string;
  holdingsVolume: number;
  id: string;
  name: string;
  representative: AccountUser;
  status: AccountApiStatus;
  type: AccountType;
};

export type AccountCreationResponse = {
  accountId: string;
};

export type NewAccountRepRoles = typeof NewAccountRepRoles[keyof typeof NewAccountRepRoles];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewAccountRepRoles = {
  accountRepFullAccess: 'accountRepFullAccess',
  accountRepApprover: 'accountRepApprover',
  accountRepInitiator: 'accountRepInitiator',
  readOnly: 'readOnly',
} as const;

export type NewAccountRepresentativeItem = {
  email: string;
  role: NewAccountRepRoles;
};

export type LogisticsProviderAccountData = {
  type: string;
};

export type AirTransportAccountDataType = typeof AirTransportAccountDataType[keyof typeof AirTransportAccountDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AirTransportAccountDataType = {
  airTransportProviderHoldingAccount: 'airTransportProviderHoldingAccount',
  certificationBody: 'certificationBody',
  fuelProviderHoldingAccount: 'fuelProviderHoldingAccount',
  generalHoldingAccount: 'generalHoldingAccount',
  logisticsProviderHoldingAccount: 'logisticsProviderHoldingAccount',
} as const;

export type AirTransportAccountData = {
  icaoAirlineDesignator?: string;
  type: AirTransportAccountDataType;
};

export type GeneralAccountData = {
  type: string;
};

export type FuelProviderAccountDataRequestType = typeof FuelProviderAccountDataRequestType[keyof typeof FuelProviderAccountDataRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FuelProviderAccountDataRequestType = {
  airTransportProviderHoldingAccount: 'airTransportProviderHoldingAccount',
  certificationBody: 'certificationBody',
  fuelProviderHoldingAccount: 'fuelProviderHoldingAccount',
  generalHoldingAccount: 'generalHoldingAccount',
  logisticsProviderHoldingAccount: 'logisticsProviderHoldingAccount',
} as const;

export type FuelProviderAccountDataRequestSustainabilityCertificationSchemeItem = typeof FuelProviderAccountDataRequestSustainabilityCertificationSchemeItem[keyof typeof FuelProviderAccountDataRequestSustainabilityCertificationSchemeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FuelProviderAccountDataRequestSustainabilityCertificationSchemeItem = {
  rsbCorsia: 'rsbCorsia',
  isccCorsia: 'isccCorsia',
  rsbEuRed: 'rsbEuRed',
  isccEu: 'isccEu',
  rsbGlobal: 'rsbGlobal',
  isccPlus: 'isccPlus',
} as const;

export type FuelProviderAccountDataRequest = {
  sustainabilityCertificationScheme: FuelProviderAccountDataRequestSustainabilityCertificationSchemeItem[];
  type: FuelProviderAccountDataRequestType;
};

export type CertificationBodyAccountData = { [key: string]: any };

export type UpdateAccountRequestAccountData = CertificationBodyAccountData | FuelProviderAccountDataRequest | GeneralAccountData | AirTransportAccountData | LogisticsProviderAccountData;

export type CreateAccountRequestAccountData = CertificationBodyAccountData | FuelProviderAccountDataRequest | GeneralAccountData | AirTransportAccountData | LogisticsProviderAccountData;

export type CreateAccountRequest = {
  accountData: CreateAccountRequestAccountData;
  accountDocuments: Blob[];
  accountName: string;
  accountRepresentativesToJoin: NewAccountRepresentativeItem[];
  joinCompanyRepresentativeAsAccountRepresentative: boolean;
  requiredSignatures: number;
};

export type ToggleTrustedAccountRequest = {
  trusted: boolean;
  trustedAccountId: string;
};

export type AccountInfoResponseType = typeof AccountInfoResponseType[keyof typeof AccountInfoResponseType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountInfoResponseType = {
  airTransportProviderHoldingAccount: 'airTransportProviderHoldingAccount',
  certificationBody: 'certificationBody',
  fuelProviderHoldingAccount: 'fuelProviderHoldingAccount',
  generalHoldingAccount: 'generalHoldingAccount',
  logisticsProviderHoldingAccount: 'logisticsProviderHoldingAccount',
} as const;

export type AccountInfoResponseAccountStatus = typeof AccountInfoResponseAccountStatus[keyof typeof AccountInfoResponseAccountStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountInfoResponseAccountStatus = {
  awaitingReview: 'awaitingReview',
  active: 'active',
  blocked: 'blocked',
  closed: 'closed',
  rejected: 'rejected',
  removed: 'removed',
} as const;

export type AccountBlockReason = typeof AccountBlockReason[keyof typeof AccountBlockReason];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountBlockReason = {
  NotEnoughApprovers: 'NotEnoughApprovers',
  CompanyBlocked: 'CompanyBlocked',
} as const;

export type FuelProviderAccountDataResponseSustainabilityCertificationSchemeItem = typeof FuelProviderAccountDataResponseSustainabilityCertificationSchemeItem[keyof typeof FuelProviderAccountDataResponseSustainabilityCertificationSchemeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FuelProviderAccountDataResponseSustainabilityCertificationSchemeItem = {
  rsbCorsia: 'rsbCorsia',
  isccCorsia: 'isccCorsia',
  rsbEuRed: 'rsbEuRed',
  isccEu: 'isccEu',
  rsbGlobal: 'rsbGlobal',
  isccPlus: 'isccPlus',
} as const;

export type FuelProviderAccountDataResponse = {
  documents: string[];
  sustainabilityCertificationScheme: FuelProviderAccountDataResponseSustainabilityCertificationSchemeItem[];
};

export type AirTransportAccountDataResponse = {
  icaoAirlineDesignator?: string;
};

export type SimpleAccountDataResponse = { [key: string]: any };

export type AccountInfoResponseAccountData = SimpleAccountDataResponse | AirTransportAccountDataResponse | FuelProviderAccountDataResponse;

export type AccountInfoResponse = {
  accountData: AccountInfoResponseAccountData;
  accountStatus: AccountInfoResponseAccountStatus;
  approversCount: number;
  blockReasons: AccountBlockReason[];
  companyId: string;
  companyName: string;
  documents: string[];
  id: string;
  name: string;
  requiredSignatures: number;
  type: AccountInfoResponseType;
};

export type SimpleAccountResponseType = typeof SimpleAccountResponseType[keyof typeof SimpleAccountResponseType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimpleAccountResponseType = {
  airTransportProviderHoldingAccount: 'airTransportProviderHoldingAccount',
  certificationBody: 'certificationBody',
  fuelProviderHoldingAccount: 'fuelProviderHoldingAccount',
  generalHoldingAccount: 'generalHoldingAccount',
  logisticsProviderHoldingAccount: 'logisticsProviderHoldingAccount',
} as const;

export type SimpleAccountResponse = {
  companyName: string;
  id: string;
  name: string;
  trusted: boolean;
  type: SimpleAccountResponseType;
};

export type RevokeCRInvitationRequest = {
  email: string;
};

export type AddCompanyRepResponse = {
  errors: string[];
  processedEmails: string[];
};

export type AddCompanyRepRequest = {
  emails: string[];
};

export type UpdateCompanyDocumentsRequest = {
  uploads: Blob[];
};

export type UpdateCompanyNamePrivacyRequest = {
  namePrivacyEnabled: boolean;
};

export type UpdatedCompanyDetailsPayload = {
  address?: string;
  address2?: string;
  address3?: string;
  city?: string;
  /** ISO2 country code */
  country?: string;
  name?: string;
  postcode?: string;
  state?: string;
  website?: string;
};

export type UpdateCompanyRequest = {
  details: UpdatedCompanyDetailsPayload;
};

export type CompanyStatus = typeof CompanyStatus[keyof typeof CompanyStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyStatus = {
  active: 'active',
  blocked: 'blocked',
  closed: 'closed',
  removed: 'removed',
  awaitingReview: 'awaitingReview',
} as const;

export type CompanyListResponseItem = {
  companyId: string;
  companyName: string;
  cr: CompanyListResponseItemCr;
  isRaCompany: boolean;
  status: CompanyStatus;
};

export type CompanyMembershipStatus = typeof CompanyMembershipStatus[keyof typeof CompanyMembershipStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyMembershipStatus = {
  active: 'active',
  blocked: 'blocked',
} as const;

export type CompanyListItemCr = {
  crEmail: string;
  crLastName: string;
  crName: string;
  crStatus: CompanyMembershipStatus;
};

export type CompanyListResponseItemCr = CompanyListItemCr | null;

export type CompanyResponseStatus = typeof CompanyResponseStatus[keyof typeof CompanyResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyResponseStatus = {
  active: 'active',
  blocked: 'blocked',
  closed: 'closed',
  removed: 'removed',
  awaitingReview: 'awaitingReview',
} as const;

export type CompanyActions = {
  canAddRaToCompany: boolean;
};

export type CompanyDetailsResponse = {
  address: string;
  address2: string;
  address3: string;
  city: string;
  country: string;
  email: string;
  name: string;
  postcode: string;
  state: string;
  uploads: string[];
  website: string;
};

export type CompanyResponse = {
  actions: CompanyActions;
  details: CompanyDetailsResponse;
  id: string;
  isRaCompany: boolean;
  name: string;
  namePrivacyEnabled: boolean;
  status: CompanyResponseStatus;
};

export type UploadFileRequest = {
  file: Blob;
};

export type FileDetailsResponse = {
  createdAt: string;
  id: string;
  mimetype: string;
  name: string;
  size: number;
};

